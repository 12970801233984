import { Card, Typography } from "@material-tailwind/react";

const AuthForm = ({ 
    children, className,
    tagline, handleSubmit
  }) => {
  return (
    <Card className={className} shadow={false}>
      <Typography
        variant="h2"
        className="text-tempo-black text-2xl md:text-4xl"
      >
        {tagline}
      </Typography>
      <form className="flex flex-col gap-y-4 p-4" onSubmit={handleSubmit}>
        {children}
      </form>
    </Card>
  );
}

export default AuthForm;