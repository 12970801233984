import {
  Link,
  Element
} from "react-scroll";

import { Typography } from "@material-tailwind/react";

const PrivacyPolicy = () => {
  return (<>
    <div
      className="shadow-md shadow-tempo-white bg-tempo-gray md:rounded-md md:mx-[8.33%] md:my-5 p-5 text-tempo-black text-sm font-roboto"
    >
      <div
        className="flex flex-col gap-4"
      >
        <Typography
          variant="h1"
        >
          Moonbase Privacy Policy
        </Typography>
        <Typography
          variant="h2"
          className="text-3xl"
        >
          End User License Agreement
        </Typography>
        <Typography
          variant="h3"
          className="text-sm"
        >
          Last updated December 22nd, 2023
        </Typography>
      </div>
      <hr
        className="my-5 border-tempo-black"
      />
      <nav
        className="list-decimal ml-7"
      >
        <Link
          to="the-application"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          The Application
        </Link>
        <Link
          to="scope-of-license"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Scope of License
        </Link>
        <Link
          to="technical-requirements-1"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Technical Requirements
        </Link>
        <Link
          to="technical-requirements-2"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Technical Requirements
        </Link>
        <Link
          to="user-generated-contributions"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          User Generated Contribution
        </Link>
        <Link
          to="contribution-license"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Contribution License
        </Link>
        <Link
          to="liability"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Liability
        </Link>
        <Link
          to="warranty"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Warranty
        </Link>
        <Link
          to="product-claims"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Product Claims
        </Link>
        <Link
          to="legal-compliance"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Legal Compliance
        </Link>
        <Link
          to="contact-information"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Contact Information
        </Link>
        <Link
          to="termination"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Termination
        </Link>
        <Link
          to="third-party-terms-of-agreements-and-beneficiary"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Third Party Terms of Agreements and Beneficiary
        </Link>
        <Link
          to="intellectual-property-rights"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Intellectual Property Rights
        </Link>
        <Link
          to="applicable-law"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Applicable Law
        </Link>
        <Link
          to="miscellaneous"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Miscellaneous
        </Link>
        <Link
          to="moonbases-privacy-policy-agreement"
          smooth={true}
          offset={-70}
          duration={300}
          className="list-item w-fit cursor-pointer hover:text-tempo-blue hover:underline duration-300"
        >
          Moonbase's Privacy Policy Agreement
        </Link>
      </nav>
      <hr
        className="my-5 border-tempo-black"
      />
      <div
        className="leading-8 font-medium *:my-5"
      >
        <div
          className="flex flex-col gap-4"
        >
          <p>
            Moonbase is licensed to You (End-User) by Tempospace INC, or Tempospace, 
            located and registered at 601 Dr Martin Luther King Jr Blvd Ste A, 601 
            MLK, Ste A, Clarksdale, Mississippi 38614, United States (hereinafter: 
            Licensor), for use only under the terms of this License Agreement.
          </p>
          <p>
            By downloading the Application from the Apple AppStore or Google's Play 
            Store, and any update thereto (as permitted by this License Agreement), 
            You indicate that You agree to be bound by all of the terms and conditions 
            of this License Agreement, & Moonbase's Privacy Policy, and that You 
            accept this License Agreement & Moonbase's Privacy Policy.
          </p>
          <p>
            The parties of this License Agreement acknowledge that Apple or Google's 
            Play Store is not a Party to this License Agreement and is not bound by 
            any provisions or obligations with regard to the Application, such as 
            warranty, liability, maintenance, and support thereof. Tempospace, not 
            Apple or Google's Play Store, is solely responsible for the licensed 
            application and the content thereof.
          </p>
          <p>
            This License Agreement may not provide for usage rules for the Application 
            that is in conflict with the latest App Store Terms of Service (or 
            Google's Play Store). Tempospace acknowledges that it had the opportunity 
            to review said terms and this License Agreement is not conflicting with 
            them.
          </p>
          <p>
              All rights not expressly granted to You are reserved.
          </p>
        </div>
        <Element name="the-application">
          <section>
            <Typography variant="h4" className="my-3">1. THE APPLICATION</Typography>
            <p>
              Moonbase (hereinafter: Application) is a piece of software created to the 
              purpose of the application is to educate & train using short-form content 
              – and customized for Apple or Google's Play Store mobile devices. The main 
              use is connecting onboarding & training employees or granting information 
              within an organization to the user using short-form content.
            </p>
            <p>
              The Application is not tailored to comply with industry-specific 
              regulations (Health Insurance Portability and Accountability Act (HIPAA), 
              Federal Information Security Management Act (FISMA), etc.), so if your 
              interactions would be subjected to such laws, you may not use this 
              Application. You may not use the Application in a way that would violate 
              the Gramm-Leach-Bliley Act (GLBA).
            </p>
          </section>
        </Element>
        <Element name="scope-of-license">
          <section>
            <Typography variant="h4" className="my-3">2. SCOPE OF LICENSE</Typography>
            <p>
              2.1 You are given a non-transferable, non-exclusive, non-sublicensable 
              license to install and use the Licensed Application on any Apple-branded 
              or Google's Play Store products that You (End-User) own or control and as 
              permitted by the Usage Rules set forth in this section and the App Store 
              Terms of Service or Google's Play Store, with the exception that such 
              licensed Application may be accessed and used by other accounts associated 
              with You (End-User, The Purchaser) via Family Sharing or volume purchasing.
            </p>
            <p>
              2.2 This license will also govern any updates of the Application provided 
              by Licensor that replace, repair, and/or supplement the first Application 
              unless a separate license is provided for such update in which case the 
              terms of that new license will govern.
            </p>
            <p>
              2.3 You may not share or make the Application available to third parties 
              (unless to the degree allowed by the Apple Terms and Conditions, or 
              Google's Play Store, and with Tempospace INC prior written consent), sell, 
              rent, lend, lease or otherwise redistribute the Application.
            </p>
            <p>
              2.4 You may not reverse engineer, translate, disassemble, integrate, 
              decompile, remove, modify, combine, create derivative works or updates of, 
              adapt, or attempt to derive the source code of the Application, or any 
              part thereof (except with Tempospace prior written consent).
            </p>
            <p>
              2.5 You may not copy (excluding when expressly authorized by this license 
              and the Usage Rules) or alter the Application or portions thereof. You may 
              create and store copies only on devices that You own or control for backup 
              keeping under the terms of this license, the App Store Terms of Service or 
              Google's Play Store, and any other terms and conditions that apply to the 
              device or software used. You may not remove any intellectual property 
              notices. You acknowledge that no unauthorized third parties may gain 
              access to these copies at any time.
            </p>
            <p>
              2.6 Violations of the obligations mentioned above, as well as the attempt 
              of such infringement, may be subject to prosecution and damages.
            </p>
            <p>
              2.7 Licensor reserves the right to modify the terms and conditions of 
              license.
            </p>
            <p>
              2.8 Nothing in this license should be interpreted to restrict third-party 
              terms. When using the Application, You must ensure that You comply with 
              applicable third-party terms and conditions.
            </p>
          </section>
        </Element>
        <Element id="technical-requirements-1">
          <section>
            <Typography variant="h4" className="my-3">3. TECHNICAL REQUIREMENTS</Typography>
            <p>
              3.1 Licensor attempts to keep the Application updated so that it complies 
              with modified/new versions of the firmware and new hardware. You are not 
              granted right to claim such an update.
            </p>
            <p>
              3.2 You acknowledge that it is Your responsibility to confirm and 
              determine that the app end-user device on which You intend to use the 
              Application satisfies the technical specifications mentioned above.
            </p>
            <p>
              3.3 Licensor reserves the right to modify the technical specifications as
              it sees appropriate at any time.
            </p>
          </section>
        </Element>
        <Element name="technical-requirements-2">
          <section>
            <Typography variant="h4" className="my-3">4. TECHNICAL REQUIREMENTS</Typography>
            <p>
              4.1 The Licensor is solely responsible for providing any maintenance and 
              support services for this licensed Application. You can reach the Licensor 
              at the email address listed in the App Store Overview, or Google's Play 
              Store, for this licensed Application.
            </p>
            <p>
              4.2 Tempospace and the End-User acknowledge that Apple or Google's Play 
              Store has no obligation whatsoever to furnish any maintenance and support 
              services with respect to the licensed Application.
            </p>
            <p>
              4.3 Tempospace ensures the utmost security for users of Moonbase. 
              Additional services can be purchased, or enabled, if the user seeks 
              subscription plans with more security features. This is outlined in 
              Moonbase's pricing plan or through written consent from Tempospace.
            </p>
          </section>
        </Element>
        <Element name="user-generated-contributions">
          <section>
            <Typography variant="h4" className="my-3">5. USER-GENERATED CONTRIBUTIONS</Typography>
            <p>
              The Application may invite you to chat, contribute to, or participate in 
              blogs, message boards, online forums, and other functionality, and may 
              provide you with the opportunity to create, submit, post, display, 
              transmit, perform, publish, distribute, or broadcast content and materials 
              to us or in the Application, including but not limited to text, writings, 
              video, audio, photographs, graphics, comments, suggestions, or personal 
              information or other material (collectively, “Contributions”). 
              Contributions may be viewable by other users of the Application and 
              through third-party websites or applications. As such, any Contributions 
              you transmit may be treated as non-confidential and non-proprietary. When 
              you create or make available any Contributions, you thereby represent and 
              warrant that:
            </p>
            <ol>
              <li>
                The creation, distribution, transmission, public display, or performance, 
                and the accessing, downloading, or copying of your Contributions do not 
                and will not infringe the proprietary rights, including but not limited 
                to the copyright, patent, trademark, trade secret, or moral rights of 
                any third party.
              </li>
              <li>
                You are the creator and owner of or have the necessary licenses, rights, 
                consents, releases, and permissions to use and to authorize us, the 
                Application, and other users of the Application to use your 
                Contributions in any manner contemplated by the Application and these 
                Terms of Use.
              </li>
              <li>
                You have the written consent, release, and/or permission of each and 
                every identifiable individual person in your Contributions to use the 
                name or likeness or each and every such identifiable individual person 
                to enable inclusion and use of your Contributions in any manner 
                contemplated by the Application and these Terms of Use.
              </li>
              <li>
                Your Contributions are not false, inaccurate, or misleading.
              </li>
              <li>
                Your Contributions are not unsolicited or unauthorized advertising, 
                promotional materials, pyramid schemes, chain letters, spam, mass 
                mailings, or other forms of solicitation.
              </li>
              <li>
                Your Contributions are not obscene, lewd, lascivious, filthy, violent, 
                harassing, libelous, slanderous, or otherwise objectionable (as 
                determined by us).
              </li>
              <li>
                Your Contributions do not ridicule, mock, disparage, intimidate, or 
                abuse anyone.
              </li>
              <li>
                Your Contributions are not used to harass or threaten (in the legal 
                sense of those terms) any other person and to promote violence against a 
                specific person or class of people.
              </li>
              <li>
                Your Contributions do not violate any applicable law, regulation, or 
                rule.
              </li>
              <li>
                Your Contributions do not violate the privacy or publicity rights of any 
                third party.
              </li>
              <li>
                Your Contributions do not violate any applicable law concerning child 
                pornography, or otherwise intended to protect the health or well-being 
                of minors.
              </li>
              <li>
                Your Contributions do not include any offensive comments that are 
                connected to race, national origin, gender, sexual preference, or 
                physical handicap.
              </li>
              <li>
                Your Contributions do not otherwise violate, or link to material that 
                violates, any provision of these Terms of Use, or any applicable law or 
                regulation.
              </li>
            </ol>
            <p>
              Your Contributions on any services of Moonbase shall not violate any of 
              the formalities listed on Moonbase's, or Tempo's, Community Agreement 
              guidelines, which includes but is not limited to, Inappropriate content, 
              Sexual Content, Animal Abuse, Harassment, Self-Harm, Dangerous or Harmful 
              Events (Or Chats), Human Trafficking, Illegal Actions, Spam Invasion of 
              Privacy, Hacking, Tools of Harm, or Child Sexual Abuse. Any use of the 
              Application in violation of the foregoing violates these Terms of Use and 
              may result in, among other things, termination or suspension of your 
              rights to use the Application.
            </p>
          </section>
        </Element>
        <Element name="contribution-license">
          <section>
            <Typography variant="h4" className="my-3">6. CONTRIBUTION LICENSE</Typography>
            <p>
              By posting your Contributions to any part of the Application or making 
              Contributions accessible to the Application by linking your account from 
              the Application to any of your social networking accounts, you 
              automatically grant, and you represent and warrant that you have the right 
              to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-
              exclusive, transferable, royalty-free, fully-paid, worldwide right, and 
              license to host, use copy, reproduce, disclose, sell, resell, publish, 
              broadcast, retitle, archive, store, cache, publicly display, reformat, 
              translate, transmit, excerpt (in whole or in part), and distribute such 
              Contributions (including, without limitation, your image and voice) for 
              any purpose, commercial advertising, or otherwise, and to prepare 
              derivative works of, or incorporate in other works, such as Contributions, 
              and grant and authorize sublicenses of the foregoing. The use and 
              distribution may occur in any media format and through any media channel.
            </p>
            <p>
              This license will apply to any form, media, or technology now known or 
              hereafter developed, and includes our use of your name, company name, and 
              franchise name, as applicable, and any of the trademarks, service marks, 
              trade names, logos, and personal and commercial images you provide. You 
              waive all moral rights in your Contributions, and you warrant that moral 
              rights have not otherwise been asserted in your Contributions.
            </p>
            <p>
              We do not assert any ownership over your Contributions. You retain full 
              ownership of all of your Contributions and any intellectual property 
              rights or other proprietary rights associated with your Contributions. We 
              are not liable for any statements or representations in your Contributions 
              provided by you in any area in the Application. You are solely responsible 
              for your Contributions to the Application and you expressly agree to 
              exonerate us from any and all responsibility and to refrain from any legal 
              action against us regarding your Contributions.
            </p>
            <p>
              We have the right, in our sole and absolute discretion, (1) to edit, 
              redact, or otherwise change any Contributions; (2) to re-categorize any 
              Contributions to place them in more appropriate locations in the 
              Application; and (3) to pre-screen or delete any Contributions at any time 
              and for any reason, without notice. We have no obligation to monitor your 
              Contributions.
            </p>
          </section>
        </Element>
        <Element name="liability">  
          <section>
            <Typography variant="h4" className="my-3">7. LIABILITY</Typography>
            <p>
              7.1 Licensor's responsibility in the case of violation of obligations and 
              tort shall be limited to intent and gross negligence. Only in case of a 
              breach of essential contractual duties (cardinal obligations), Licensor 
              shall also be liable in case of slight negligence. In any case, liability 
              shall be limited to the foreseeable, contractually typical damages. The 
              limitation mentioned above does not apply to injuries to life, limb, or 
              health. 
            </p>
            <p>
              7.2 Licensor takes no accountability or responsibility for any damages 
              caused due to a breach of duties according to Section 2 of this Agreement. 
              To avoid data loss, You are required to make use of backup functions of 
              the Application to the extent allowed by applicable third-party terms and 
              conditions of use. You are aware that in case of alterations or 
              manipulations of the Application, You will not have access to a licensed 
              Application. 
            </p>
            <p>
              7.3 Licensor takes no accountability and responsibility in case of Any 
              harm done amongst users will not be taken lightly and, the company can 
              terminate a user for unlawful, hurtful, or inappropriate content.
            </p>
            <p>
              7.4 You are responsible & held liable for accepting/reading the Terms of 
              Conditions/Private Policy, using Moonbase's services with your age being 
              over 13 years of age, or using Moonbase's services with other parties over 
              the age of 13, and you are responsible for abiding by rules below: 
              Unauthorized access or dissemination of sensitive information protected by 
              the Children's Online Privacy Protection Rule (“COPPA”), which is codified 
              in CFR :: 16 CFR Part 312 — Children's Online Privacy Protection Rule and 
              enforced by the Federal Trade Commission (ftc.gov) is strictly prohibited 
              and will subject the person or engaged in such conduct to any and civil 
              and criminal penalties authorized by law.
            </p>
            <p>
              Additionally, if rules above are deemed broken by Tempospace, Disciplinary 
              action may consist of anything from verbal written warnings and 
              counselling to demotion, transfer, suspension, or termination.
            </p>
          </section>
        </Element>
        <Element name="warranty">
          <section>
            <Typography variant="h4" className="my-3">8. WARRANTY</Typography>
            <p>
              8.1 Licensor warrants that the Application is free of spyware, trojan 
              horses, viruses, or any other malware at the time of Your download. 
              Licensor warrants that the Application works as described in the user 
              documentation.
            </p>
            <p>
              8.2 No warranty is provided for the Application that is not executable on 
              the device, that has been unauthorizedly modified, handled inappropriately 
              or culpably, combined or installed with inappropriate hardware or software, 
              used with inappropriate accessories, regardless if by Yourself or by third 
              parties, or if there are any other reasons outside of Tempospace's sphere 
              of influence that affect the executability of the Application.
            </p>
            <p>
              8.3 You are required to inspect the Application immediately after 
              installing it and notify Tempospace about issues discovered without delay 
              by e-mail provided in &nbsp;
              <Link
                to="#contact-information"
                className="text-tempo-blue underline"
              >
                Contact Information
              </Link>. 
              The defect report will be taken into consideration and further 
              investigated if it has been mailed within a period of zero (0) days after 
              discovery.
            </p>
            <p>
              8.4 If we confirm that the Application is defective, Tempospace reserves a 
              choice to remedy the situation either by means of solving the defect or 
              substitute delivery.
            </p>
            <p>
              8.5 In the event of any failure of the Application to conform to any 
              applicable warranty, You may notify the App-Store-Operator, and Your 
              Application purchase price will be refunded to You. To the maximum extent 
              permitted by applicable law, the App-Store-Operator will have no other 
              warranty obligation whatsoever with respect to the App, and any other 
              losses, claims, damages, liabilities, expenses, and costs attributable to 
              any negligence to adhere to any warranty.
            </p>
            <p>
              8.6 If the user is an entrepreneur, any claim based on faults expires 
              after a statutory period of limitation amounting to twelve (12) months 
              after the application was made available to the user. The statutory 
              periods of limitation given by law apply for users who are consumers.
            </p>
          </section>
        </Element>
        <Element name="product-claims">
          <section>
            <Typography variant="h4" className="my-3">9. PRODUCT CLAIMS</Typography>
            <p>
              Tempospace and the End-User acknowledge that Tempospace, and not Apple or 
              Google's Play Store, is responsible for addressing any claims of the End-
              User or any third party relating to the licensed Application or the End-
              User's possession and/or use of that licensed Application, including, but 
              not limited to:
            </p>
            <p>(i) product liability claims;</p>
            <p>
              (ii) any claim that the licensed Application fails to conform to any 
              applicable legal or regulatory requirement; and
            </p>
            <p>
              (iii) claims to arise under consumer protection, privacy, or similar 
              legislation, including in connection with Your Licensed Application's use 
              of the HealthKit and HomeKit.
            </p>
          </section>
        </Element>
        <Element name="legal-compliance">
          <section>
            <Typography variant="h4" className="my-3">10. LEGAL COMPLIANCE</Typography>
            <p>
              You represent and warrant that You are not located in a country that is 
              subject to a U.S. Government embargo, or that has been designated by the 
              U.S. Government as a “terrorist supporting” country; and that You are not 
              listed on any U.S. Government list of prohibited or restricted parties.
            </p>
          </section>
        </Element>
        <Element name="contact-information">
          <section>
            <Typography variant="h4" className="my-3">11. CONTACT INFORMATION</Typography>
            <p>
              For general inquiries, complaints, questions or claims concerning the 
              licensed Application, please contact:
            </p>
            <address>
              Ryan Hopson
              <br />
              601 Dr Martin Luther King Jr Blvd Ste A
              <br />
              601 MLK, Ste A
              <br />
              Clarksdale, MS 38614
              <br />
              United States
              <br />
              ryan.hopson@tempospace.co
            </address>
          </section>
        </Element>
        <Element name="termination">
          <section>
            <Typography variant="h4" className="my-3">12. TERMINATION</Typography>
            <p>
              The license is valid until terminated by Tempospace or by You. Your rights
              under this license will terminate automatically and without notice from 
              Tempospace if You fail to adhere to any term(s) of this license. Upon 
              License termination, You shall stop all use of the Application, and 
              destroy all copies, full or partial, of the Application.
            </p>
          </section>
        </Element>
        <Element name="third-party-terms-of-agreements-and-beneficiary">
          <section>
            <Typography variant="h4" className="my-3">13. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</Typography>
            <p>
              Tempospace represents and warrants that Temposace will comply with 
              applicable third-party terms of agreement when using licensed Applications. 
              In accordance with Section 9 of the “Instructions for Minimum Terms of 
              Developer's End-User License Agreement,” Apple and Apple's subsidiaries, 
              or Google & Google's subsidiaries, shall be third-party beneficiaries of 
              this End User License Agreement and – upon Your acceptance of the terms 
              and conditions of this license agreement, Apple or Google will have the 
              right (and will be deemed to have accepted the right) to enforce this End 
              User License Agreement against You as a third-party beneficiary thereof.
            </p>
          </section>
        </Element>
        <Element name="intellectual-property-rights">
          <section>
            <Typography variant="h4" className="my-3">14. INTELLECTUAL PROPERTY RIGHTS</Typography>
            <p>
              Tempospace and the End-User acknowledge that, in the event of any third-
              party claim that the licensed Application or the End-User's possession and 
              use of that licensed Application infringe on the third party's 
              intellectual property rights, Tempospace, and not Apple or Google's Play 
              Store, will be solely responsible for the investigation, defense, 
              settlement, and discharge of any such intellectual property infringement 
              claims.
            </p>
          </section>
        </Element>
        <Element name="applicable-law">
          <section>
            <Typography variant="h4" className="my-3">15. APPLICABLE LAW</Typography>
            <p>
              This license agreement is governed by the laws of the State of Mississippi 
              excluding its conflicts of law rules.
            </p>
          </section>
        </Element>
        <Element name="miscellaneous">
          <section>
            <Typography variant="h4" className="my-3">16. MISCELLANEOUS</Typography>
            <p>
              16.1 If any of the terms of this agreement should be or become invalid, 
              the validity of the remaining provisions shall not be affected. Invalid 
              terms will be replaced by valid ones formulated in a way that will achieve 
              the primary purpose.
            </p>
            <p>
              16.2 Collateral agreements, changes, and amendments are only valid if laid 
              down in writing. The preceding clause can only be waived in writing.
            </p>
            <p>
              16.3 By downloading the software, the company, Tempospace, cannot do any 
              other function except for making the services of Moonbase work well. 
              Anything outside of Moonbase's, or Tempospace's, work is not liable to 
              Tempospace. If there are any questions or concerns, please contact us. 
              These terms and agreements apply to Android users, the Play store, or all 
              users of cellular phones who have the Moonbase application.
            </p>
          </section>
        </Element>
        <Element name="moonbases-privacy-policy-agreement">
          <section>
            <Typography variant="h4" className="my-3">17. Moonbase's Privacy Policy Agreement</Typography>
            <Typography variant="h5" className="my-2">Last updated December 18th, 2023</Typography>
            <p>
              Moonbase takes the privacy of their user's data seriously. By continuing 
              to use our application, you agree to the policies outlined in this 
              document. This Privacy Policy explains our commitment and the choices for 
              how we collect data & processes and your choices about how the data is 
              collected and used.
            </p>
            <h4>The Information Collected</h4>
            <p>
              We collect information to optimize only your user experience within the 
              application. All technology back-end servers are guarded with industry-
              strength security. We collect information for the operation of the 
              application included but not limited:
            </p>
            <ul>
              <li>Creating an Account</li>
              <li>Creating and Viewing Events, Courses, & Other Profiles</li>
              <li>Message with Others</li>
              <li>Creating/Editing Videos</li>
              <li>Quiz Information</li>
              <li>Interests & Groups Involved</li>
              <li>Usage</li>
              <li>Transactions</li>
              <li>Device Information</li>
              <li>Partner Information</li>
            </ul>
            <p>
              The information collected mostly includes the login information of your 
              name, email address, IP address, contract information, and interests. 
              Hypothesis of the data are derived to enhance your viewing experience and 
              allow you to participate fully with the content.
            </p>
            <p>
              Usage, Transactions, Device Information, and Partner Information are 
              collected to ensure no fraudulent activity is on the account. Furthermore, 
              crash analytics keeps us posted on the application service. Device 
              information & usage helps with that.
            </p>
            <h4>How do we use this information?</h4>
            <p>
              We use the information provided to personalize your experience on Moonbase, 
              for example, displayings courses in your home page that you expressed 
              interest in. We use location-related information, like current location, 
              to suggest courses near you. We use information to develop, test, and 
              research products for continuing developing Tempo's service. We use 
              information to personalize the advertisements you see. We total 
              information for the analytics of partners. Lastly, we use information to 
              adhere to the community guidelines set in the Terms & Agreements, promote 
              social good, and for you to communicate to us.
            </p>
            <h4>How can I manage or delete information about me?</h4>
            <p> Please contact us and we can help you through the process.</p>
            <h4>Cookies</h4>
            <p>
              Cookies store information about your preferences and visits to our site 
              and other cities. We use cookies, if acceptable, to customize the 
              experience.
            </p>
            <h4>Disclosure of Information</h4>
            <p>
              Personal information will not be given out except in accordance with the 
              law. We may share your information with service providers or vendors to 
              tailor Tempo for your experience. We may also share information with our 
              partners for promotional passions or courses that benefit you.
            </p>
            <h4>Security</h4>
            <p>
              All technical and organizational measures to protect your personal 
              information from unauthorized access are implemented. Multiple layers of 
              protection are instated - from coding, authorization, visibility, and 
              chains of command. Even so, information on our servers are encrypted, so 
              that the data transmitted aren't visible from our end.
            </p>
            <h4>Changes to this Privacy Policy</h4>
            <p>
              We may make changes to this document which you will be notified either by 
              email or phone in our app. Your continued use of service means you accept 
              these changes.
            </p>
            <h4>Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy, please 
              contact Tempo at &nbsp;
              <a 
                href="mailto:ryan.hopson@tempospace.co"
                className="text-tempo-blue underline"
              >
                ryan.hopson@tempospace.co
              </a>
            </p>
          </section>
        </Element>
      </div>
    </div>
  </>);
}

export default PrivacyPolicy;