import { useMemo, useState } from "react";
import { appConfig } from "../configs/main_config";
import { getAI } from "../lib/open-ai";

/**
 * A custom hook to handle the chat state and logic
 */
export function useChat() {
    const [currentChat, setCurrentChat] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [state, setState] = useState("idle");
    // < "idle" | "waiting" | "loading" > 

    // Lets us cancel the stream
    const abortController = useMemo(() => new AbortController(), []);

    /**
     * Cancels the current chat and adds the current chat to the history
     */
    function cancel() {
        setState("idle");
        abortController.abort();
        if (currentChat) {
            const newHistory = [
                ...chatHistory,
                { role: "user", content: currentChat },
            ];

            setChatHistory(newHistory);
            setCurrentChat("");
        }
    }

    /**
     * Clears the chat history
     */

    function clear() {
        console.log("clear");
        setChatHistory([]);
    }

    const sendMessage = async (
        message,
        chatHistory,
    ) => {
        setState("waiting");
        let chatContent = "";
        const newHistory = [
            ...chatHistory,
            { role: "user", content: message },
        ];
        setChatHistory(newHistory);
        const messages = newHistory.slice(-appConfig.historyLength);

        const openai = await getAI();
        const completion = await openai.chat.completions.create({
            messages,
            model: "gpt-3.5-turbo",
        });

        const response = completion.choices[0];
        setCurrentChat("...");

        if (!response) {
            setState("idle");
            return;
        }

        const { content, role } = response.message;

        if (role === "assistant") {
            chatContent = content;
        } else {
            chatContent = content;
        }
        setCurrentChat(chatContent);

        setChatHistory((curr) => [
            ...curr,
            { role: "assistant", content: chatContent },
        ]);
        setCurrentChat(null);
        setState("idle");
    };

    return { sendMessage, currentChat, chatHistory, cancel, clear, state };
}