import { Typography } from "@material-tailwind/react";

const SuccessMessage = ({
    textGradient, as, className, children
  }) => {
  return <Typography
    variant="small"
    color="green"
    textGradient={textGradient}
    as={as}
    className={[className, "flex items-center font-normal"].join(" ")}
  >
    {children}
  </Typography>
}

export default SuccessMessage;