import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { signupRequest } from "../../services/authenticationService";

import { Avatar, Card, Dialog, DialogBody, DialogHeader, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import PasswordField from "../../components/form/passwordField";
import CheckboxField from "../../components/form/checkboxField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import BottomLinks from "../../components/form/bottomLinks";

import onboardGroupIllustration from "./../../assets/images/illustration-onboard-group.svg";
import onboardIndividualIllustration from "./../../assets/images/illustration-onboard-individual.svg";

const RegisterForm = ({ title }) => {
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [signupDialogOpen, setSignupDialogOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  // sets title of form in authentication layout
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  const signupDialogOpenHandler = () => setSignupDialogOpen(!signupDialogOpen);

  const navigate = useNavigate();
  const { search } = useLocation();
  const onboardOrg = () => { navigate(`/onboarding${search}`) };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [firstName, lastName, email, password, confirmPassword, inviteCode] = e.target;
    if (password.value !== confirmPassword.value) {
      setErrorMessage("Password mismatch");
      return;
    } else {
      setErrorMessage("");
    }

    try {
      setDialogOpen(true);
      const signupResult = await signupRequest(firstName.value, lastName.value, email.value, password.value, inviteCode.value);
      setDialogOpen(false);

      const { success, message } = signupResult;
      if (success && message !== "User already exists") {
        if (typeof (message) !== "string") {
          // const { userId } = message.user ?? {};
        }
        navigate(`verify${search}`, { state: { emailAddress: email.value, password: password.value } });
      } else {
        if (message === "User already exists") {
          setErrorMessage("User already exists, please login instead");
        } else {
          setErrorMessage(message);
        }
      }

    } catch (e) {
      setDialogOpen(false);
      setErrorMessage(e.toString());
    }
  }

  return <>
    <Dialog aria-modal={true} open={signupDialogOpen} handler={signupDialogOpenHandler} dismiss={{ enabled: false }}>
      <DialogHeader className="flex justify-center">
        <Typography variant="h3">SIGN-UP METHOD</Typography>
      </DialogHeader>
      <DialogBody className="flex-col items-center">
        <Typography className="text-center">You're one step away from joining Moonbase! How would you like to get started?</Typography>
        <List>
          <ListItem onClick={() => setSignupDialogOpen(false)}>
            <ListItemPrefix>
              <Avatar className="w-50 h-40" variant="rounded" alt="join organization" src={onboardIndividualIllustration} />
            </ListItemPrefix>
            <div>
              <Typography variant="h4" color="blue-gray">Join Existing Organization</Typography>
              <Typography variant="small" color="gray" className="font-normal">
                If you're part of an existing organization, sign up as an individual and seamlessly become a member
              </Typography>
            </div>
          </ListItem>
          <ListItem onClick={onboardOrg}>
            <ListItemPrefix>
              <Avatar className="w-50 h-40" variant="rounded" alt="candice" src={onboardGroupIllustration} />
            </ListItemPrefix>
            <div>
              <Typography variant="h4" color="blue-gray">Create New Organization</Typography>
              <Typography variant="small" color="gray" className="font-normal">
                Ready to launch your own organization on Moonbase? Sign up here to create and lead your organization. Let's get started!
              </Typography>
            </div>
          </ListItem>
        </List>
      </DialogBody>
    </Dialog>
    <Card shadow={false}>
      <AuthForm tagline="Sign Up with Moonbase" handleSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
          <InputField label={"First Name"} placeholder={"First Name"} required={true} />
          <InputField label={"Last Name"} placeholder={"Last Name"} required={true} />
        </div>
        <InputField label="Email" placeholder={"Email"} type={"email"} required={true} />
        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
          <PasswordField minLength={8} required={true} />
          <PasswordField label="Confirm Password" placeholder={"Confirm Password"} minLength={8} required={true} />
        </div>
        <InputField label={"Invite Code"} placeholder={"Invite Code"} />

        <CheckboxField required={true} label={
          <Typography variant="small" className="text-left">
            I acknowledge that I have read and agree with the
            <a className="text-tempo-orange" href="https://moonbase.biz/pages/privacy/index.html" target="_blank" rel="noreferrer">
              &nbsp;Terms and Conditions&nbsp;
            </a>
            of using Moonbase app.
          </Typography>
        }
        />

        <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
        <FormSubmit>Sign Up</FormSubmit>
      </AuthForm>

      <BottomLinks>
        <Link to={`/auth${search}`}>Already have an account? Login instead</Link>
      </BottomLinks>
    </Card>
  </>
}

export default RegisterForm;