import NavMenuLink from "./navMenuLink";

const NavMenu = ({
  className
}) => {
  const toggleMenu = () => {
    var menu = document.getElementById('menu');
    menu.classList.toggle('hidden');
    menu.classList.toggle('w-screen');
    menu.classList.toggle('h-screen');
  }

  return (
    <nav
      className={[
        className,
        "container flex items-center justify-between px-4 md:px-0 h-full font-roboto"
      ].join(" ")}
    >
      <ul
        id="menu"
        className="hidden md:flex absolute md:relative top-0 left-0 px-10 py-24 bg-tempo-gray z-50 md:p-0 md:bg-transparent md:flex-row md:space-x-6 text-lg"
      >
        <li
          className="md:hidden z-90 fixed top-4 right-6"
        >
          <button
            type="button"
            className="text-right text-tempo-black text-5xl"
            onClick={() => toggleMenu()}
          >
            &times;
          </button>
        </li>
        <NavMenuLink
          to={"/"}
          label={"Home"}
        />
        <NavMenuLink
          to={"/auth"}
          label={"Login"}
        />
        <NavMenuLink
          to={"/download"}
          label={"Download"}
        />
      </ul>
      <button
        className="absolute right-6 md:hidden opacity-70 hover:opacity-100 text-tempo-black text-4xl font-bold duration-300"
        onClick={() => toggleMenu()}
      >
        &#9776;
      </button>
    </nav>
  );
}

export default NavMenu;