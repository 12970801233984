import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";

const MobileAppLogoutForm = (props) => {

  const navigate = useNavigate();

  const openApp = useCallback(async (e) => {
    navigate(0);
  }, [navigate]);

  return <Card className="max-w-md" shadow={false}>
    <AuthForm tagline="Logging Out!">
      <Typography>You will be redirected to the mobile app shortly. If you're not, click the button below to open the app.</Typography>
      <Button color="deep-orange" onClick={openApp}>LAUNCH APP</Button>
    </AuthForm>
  </Card>
}

export default MobileAppLogoutForm;