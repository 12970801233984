import { apiBaseUrl } from "../config/apiBaseUrl";

export const httpRequest = (endpoint = '/', method = "POST", requestBody = {}, authHeader, url = apiBaseUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queries = "";
      if (requestBody !== null && method === "GET") {
        queries = "?";
        for (const [key, value] of Object.entries(requestBody)) {
          queries += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
        }
      }

      let response = await fetch(url + endpoint + queries, {
        headers: {
          'Content-Type': "application/json",
          'client_platform': 'Web',
          'Authorization': authHeader,
        },
        method: method,
        body: method === "GET" ? null : JSON.stringify(requestBody)
      });

      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        const res = await response.json();
        resolve(res);
      } else {
        const res = await response.text();
        resolve(res);
      }

    } catch (e) {
      reject(e);
    }
  });
};

export const httpRequestWithUpload = (endpoint = '/', method = "POST", file, field = '', body = {}, authHeader, url = apiBaseUrl) => {
  if (!file || !file.size) return;
  return new Promise(async (resolve, reject) => {
    try {
      const data = new FormData();
      const keys = Object.keys(body);
      if (keys.length > 0) {
        const vals = Object.values(body);
        for (let i = 0; i < keys.length; i++) {
          data.append(keys[i], vals[i]);
        }
      }
      data.append(field, file);

      let response = await fetch(url + endpoint, {
        headers: {
          'Accept': 'application/json',
          'content-length': `${file.size}`,
          'Authorization': authHeader,
        },
        method: method,
        body: data,
      });
      let res = await response.json();
      if (res.error) {
        reject(res.error);
      } else {
        resolve(res.result);
      }

    } catch (e) {
      reject(e);
    }
  });
};
