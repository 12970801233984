import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import { authStore } from "./config/states/auth_store";
import AuthProvider from "react-auth-kit/AuthProvider";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";

import AuthenticationLayout from "./components/layouts/authenticationLayout";
import LandingLayout from "./components/layouts/landingLayout";

import LoginForm from "./pages/authentication/loginForm";
import ForgotPasswordForm from "./pages/authentication/forgotPasswordForm";
import ResetPasswordForm from "./pages/authentication/resetPasswordForm";
import DeleteAccountForm from "./pages/authentication/deleteAccountForm";

import RegisterForm from "./pages/authentication/registerForm";
import VerifyAccountForm from "./pages/authentication/verifyAccountForm";

import MobileAppAuthForm from "./pages/authentication/mobileAppAuthForm";
import MobileAppLogoutForm from "./pages/authentication/mobileAppLogoutForm";

import OnboardingWizard from "./pages/onboarding/onboardingWizard";

import MoonbaseLanding from "./pages/home/moonbaseLanding";
import AITutorChatPage from "./pages/ai-tutor/chat_page";
import PrivacyPolicy from "./pages/home/privacyPolicy";
import BookDemoForm from "./pages/home/bookDemoForm";
import ContactForm from "./pages/home/contactForm";

import ErrorPageNotFound from "./pages/home/errorPageNotFound";
import DownloadApp from "./pages/home/downloadApp";

function App() {
  return (
    <AuthProvider store={authStore}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<AuthenticationLayout />}>
            <Route index={true} element={<LoginForm title={"Login"} />} />
            <Route path="forgot-password" element={<ForgotPasswordForm title={"Forgot Password"} />} />
            <Route path="reset-password" element={<ResetPasswordForm title={"Reset Password"} />} />
            <Route path="delete-account" element={<DeleteAccountForm title={"Delete Account"} />} />
          </Route>

          <Route path="/register" element={<AuthenticationLayout />}>
            <Route index={true} element={<RegisterForm title={"Register Account"} />} />
            <Route path="verify" element={<VerifyAccountForm title={"Verify Account"} />} />
          </Route>

          <Route path="/app-auth/*" element={<AuthenticationLayout />}>
            <Route element={
              <AuthOutlet
                fallbackPath={`/auth${window.location.search || "?noToken=true"}&redirectUrl=/app-auth`}
              />
            }>
              <Route index={true} element={<MobileAppAuthForm title={"App Authentication"} />} />
              <Route path="authorizeApp" element={<MobileAppAuthForm title={"App Authentication"} />} />
              <Route path="logout" element={<MobileAppLogoutForm title={"App Authentication"} />} />
            </Route>
          </Route>

          <Route path="/onboarding" element={<AuthenticationLayout />}>
            <Route index={true} element={<OnboardingWizard title={"Onboarding Wizard"} />} />
          </Route>

          <Route path="/" element={<LandingLayout />}>
            <Route index={true} element={<MoonbaseLanding />} />
            <Route path="download" element={<DownloadApp />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="contact-us" element={<ContactForm />} />
            <Route path="book-demo" element={<BookDemoForm />} />

            <Route element={<AuthOutlet fallbackPath="/auth" />}>
              <Route path="/ai-tutor" element={<AITutorChatPage />} />
            </Route>
          </Route>

          <Route path="*" element={<ErrorPageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;