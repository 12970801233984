import React from "react";

import { IdentificationIcon, EnvelopeOpenIcon, CheckBadgeIcon, CreditCardIcon  } from "@heroicons/react/24/outline";
import { Stepper, Step, Typography } from "@material-tailwind/react";

const WizardHeader = ({ 
    activeStep, setActiveStep, setIsLastStep, setIsFirstStep 
}) => {
  return (
    <div 
      className="md:mb-10 px-20 pb-8 w-full"
    >
      <Stepper
        activeStep={activeStep}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step
          onClick={() => setActiveStep(0)}
        >
          <IdentificationIcon 
            className="h-5 w-5" 
          />
          <div 
            className="absolute -bottom-[2.3rem] w-max text-center text-xs"
          >
            <Typography 
              color={activeStep === 0 ? "black" : "gray"}
              className="font-normal hidden md:block"
            >
              About You
            </Typography>
          </div>
        </Step>
        <Step
          onClick={() => setActiveStep(1)}
        >
          <EnvelopeOpenIcon
            className="h-5 w-5"
          />
          <div
            className="absolute -bottom-[2.3rem] w-max text-center text-xs"
          >
            <Typography
              color={activeStep === 1 ? "black" : "gray"}
              className="font-normal hidden md:block"
            >
              Account
            </Typography>
          </div>
        </Step>
        <Step
          onClick={() => setActiveStep(2)}
        >
          <CheckBadgeIcon 
            className="h-5 w-5"
          />
          <div
            className="absolute -bottom-[2.3rem] w-max text-center text-xs"
          >
            <Typography
              color={activeStep === 2 ? "black" : "gray"}
              className="font-normal hidden md:block"
            >
              Verify
            </Typography>
          </div>
        </Step>
        <Step
          onClick={() => setActiveStep(3)}
        >
          <CreditCardIcon
            className="h-5 w-5" 
          />
          <div
            className="absolute -bottom-[2.3rem] w-max text-center text-xs"
          >
            <Typography
              color={activeStep === 3 ? "black" : "gray"}
              className="font-normal hidden md:block"
            >
              Subscribe
            </Typography>
          </div>
        </Step>
      </Stepper>
    </div>
  );
}

export default WizardHeader;