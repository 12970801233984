import { httpRequest } from "./httpConnectionService";
import {
  changeEmailEndpoint,
  forgotPasswordEndpoint,
  loginEndpoint,
  resendVerificationEmailEndpoint,
  resetPasswordEndpoint,
  signupEndpoint,
  verifyEmailEndpoint,
  deleteAccountEndpoint
} from "../config/apiEndpoints";

const loginRequest = async (emailAddress, password) => {
  const result = await httpRequest(loginEndpoint, "POST", {
    email: emailAddress?.toLowerCase().trim(),
    password
  });
  return result;
}

const signupRequest = async (firstName, lastName, emailAddress, password, code) => {
  const result = await httpRequest(signupEndpoint, "POST", {
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    phoneNumber: "",
    password,
    confirmpassword: password,
    userType: "OWNER",
    email: emailAddress?.toLowerCase().trim(),
    code: code?.trim()
  });
  return result;
}

const changeEmailAddressRequest = async (email, password, newEmail) => {
  const result = await httpRequest(changeEmailEndpoint, "POST", {
    oldEmail: email?.toLowerCase().trim(),
    newEmail: newEmail?.toLowerCase().trim(),
    password
  });
  return result;
}

const resendVerificationEmailRequest = async (email) => {
  const result = await httpRequest(resendVerificationEmailEndpoint, "POST", {
    email: email?.toLowerCase().trim()
  });
  return result;
}

const verifyEmailAddressRequest = async (code) => {
  const result = await httpRequest(verifyEmailEndpoint + "/" + code.trim(), "GET");
  return result;
}

const forgotPasswordRequest = async (email) => {
  const result = await httpRequest(forgotPasswordEndpoint, "POST", {
    email: email?.toLowerCase().trim()
  });
  return result;
}

const resetPasswordRequest = async (email, code, password, confirmPassword) => {
  const result = await httpRequest(resetPasswordEndpoint, "POST", {
    email: email?.toLowerCase().trim(),
    code: code.trim(),
    password,
    confirmPassword
  });
  return result;
}

const deleteAccountRequest = async (email, password) => {
  const result = await httpRequest(deleteAccountEndpoint, "POST", {
    email: email?.toLowerCase().trim(),
    password
  });
  return result;
}

export {
  loginRequest,
  signupRequest,
  changeEmailAddressRequest,
  resendVerificationEmailRequest,
  verifyEmailAddressRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  deleteAccountRequest
};