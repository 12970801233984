import {
  httpRequest,
  httpRequestWithUpload
} from "./httpConnectionService";
import { createOrganizationEndpoint } from "../config/apiEndpoints";

const createOrganizationRequest = async (authHeader, organizationName, description, imageFile) => {
  const result = imageFile ? 
    await httpRequestWithUpload(createOrganizationEndpoint, "POST", imageFile, 'image', {
      organizationName: organizationName.trim(),
      description: description.trim()
    }, authHeader)
    : 
    await httpRequest(createOrganizationEndpoint, "POST", {
      organizationName: organizationName?.trim(),
      description: description?.trim()
    }, authHeader);
  return result;
}

export { createOrganizationRequest };