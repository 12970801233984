import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';

import { loginRequest } from "../../services/authenticationService";

import { Card } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import PasswordField from "../../components/form/passwordField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import BottomLinks from "../../components/form/bottomLinks";

const LoginForm = ({ title }) => {
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setTitle(title);
    // eslint-disable-next-line
  }, []);

  const signIn = useSignIn();
  const navigate = useNavigate();

  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const paramRedirectUrl = searchParams.get("redirectUrl");
  const encToken = searchParams.get("encToken");
  const redirectUrl = paramRedirectUrl ? (paramRedirectUrl + "?encToken=" + encToken) : null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [email, password] = e.target;

    try {
      setDialogOpen(true);
      const loginResult = await loginRequest(email.value, password.value);
      setDialogOpen(false);

      if (loginResult.success) {
        const { accessToken, userInfo } = loginResult;
        if (signIn({
          auth: {
            token: accessToken,
            type: 'Bearer'
          },
          refresh: accessToken,
          userState: userInfo
        })) {
          navigate(redirectUrl || "/");
        } else {
          setApiError("Unable to login, please try again later");
        }
      } else {
        if (loginResult.message === "email not verified") {
          navigate(`/register/verify${search}`, { state: { emailAddress: email.value, password: password.value, resendVerification: true } });
        } else {
          setApiError(loginResult.message || "An error occurred, please try again later");
        }
      }
    } catch (e) {
      setDialogOpen(false);
      setApiError(e.toString());
    }
  }

  return (
    <Card shadow={false}>
      <AuthForm tagline="Sign in to Moonbase" handleSubmit={handleSubmit}>
        <InputField label="Email" placeholder={"Email"} type={"email"} required={true} />
        <PasswordField required={true} />
        
        <ErrorMessage>{apiError ?? ""}</ErrorMessage>
        <FormSubmit>Log In</FormSubmit>
      </AuthForm>

      <BottomLinks>
        <Link to={`/register${search}`}>Sign Up</Link>
        <Link to={`forgot-password${search}`}>Forgot password?</Link>
      </BottomLinks>
    </Card>
  );
}

export default LoginForm;