import { appFlavor, appFlavors } from "./appFlavours";

const { REACT_APP_API_BASE_URL_DEVELOPMENT, REACT_APP_API_BASE_URL_STAGING, REACT_APP_API_BASE_URL_PRODUCTION } = process.env;

const apiBaseUrlDev = REACT_APP_API_BASE_URL_DEVELOPMENT || "https://devapi.moonbase.biz";
const apiBaseUrlStaging = REACT_APP_API_BASE_URL_STAGING || "https://stagingapi.moonbase.biz";
const apiBaseUrlProduction = REACT_APP_API_BASE_URL_PRODUCTION || "https://api.moonbase.biz";

const apiBaseUrl = appFlavor === appFlavors.development ? apiBaseUrlDev : (appFlavor === appFlavors.staging ? apiBaseUrlStaging : apiBaseUrlProduction);

export {
  apiBaseUrl
};