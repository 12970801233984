import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "@material-tailwind/react";

import LandingSection from "../../components/ui/landingSection";
import NavButton from "../../components/ui/navButton";

import moonbaseAnimation from "../../assets/videos/finalanimation.mp4";
import appleAppButton from "../../assets/images/app-ios.png";
import androidAppButton from "../../assets/images/app-android.png";
import logoMsstate from "../../assets/images/logo-msstate.png";
import logoHopsonLawFirm from "../../assets/images/logo-HopsonLawFirm.png";
import aiTutor from "../../assets/images/ai-tutor.svg";
import aiGuideGen from "../../assets/images/ai-guide-gen.svg";
import aiQuizGen from "../../assets/images/ai-quiz-gen.svg";

const MoonbaseLanding = () => {
  return (
    <>
      <LandingSection
        className="relative overflow-hidden h-screen md:h-[1100px]"
      >
        <div
          className="absolute md:h-full"
        >
          <div
            className="absolute top-0 left-0 z-[1] bg-gradient-to-b md:bg-gradient-to-r from-tempo-black w-full md:w-[60%] h-[60%] md:h-full"
          />
          <video 
            className="static md:relative top-[-100%] right-[-5%] left-0 z-[0] w-full h-auto md:h-[3125px]"

            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
          >
            <source
              src={moonbaseAnimation}
              type="video/mp4"
            />
            Your browser does not support the video tag
          </video>
        </div>
    
        <div
          className="relative z-[1] px-[8.33%] pt-[30px] h-full md:h-auto"
        >
          <div>
            <Typography
              variant="h1"
              className={[
                "my-10 md:my-20 text-tempo-orange text-[50px] md:text-[130px] font-motley",
                "plug-text-shadow-[1px_1px_10px_#551F6E,_0_0_5px_#F3F3F3,_0_0_20px_#1F1F1F]"
              ].join(" ")}
            >
              Moonbase
            </Typography>
            <Typography
              variant="h2"
              className="text-3xl md:text-5xl font-motley plug-text-shadow-[0_0_20px_#1F1F1F,_0_0_5px_#F3F3F3]"
            >
              Teach & Learn<br />
              in Seconds
            </Typography>
            <p
              className="my-5 md:w-[80%] text-[16px] md:text-[18px] plug-text-shadow-[0_0_20px_#1F1F1F,_0_0_3px_#F3F3F3]"
            >
              Using short-form content and A.I., attain, train, and retain in a matter of seconds.<br />
              Perfect for onboarding your team and customers!
            </p>
          </div>

          <div
            className="flex flex-wrap flex-col md:flex-row items-center gap-[10px] md:gap-[30px]"
          >
            <NavButton
              to={"/book-demo"}
              label={"Book a Demo"}
              className={"md:hidden"}
            />
            <a
              href="https://play.google.com/store/apps/details?id=biz.moonbase"
              className={[
                "flex overflow-hidden items-center rounded-[8px] w-fit hover:scale-110",
                "plug-text-shadow-[2px_2px_10px_#1F1F1F,_1px_1px_5px_#F3F3F3]"
              ].join(" ")}
            >
              <img
                src={androidAppButton}
                alt={"White box with Google Play logo on the left and text on the right that says Get it on Google Play"}
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/moonbase-attain-train-retain/id6476301880"
              className={[
                "flex overflow-hidden items-center rounded-[8px] w-fit hover:scale-110",
                "plug-text-shadow-[2px_2px_10px_#1F1F1F,_1px_1px_5px_#F3F3F3]"
              ].join(" ")}
            >
              <img
                src={appleAppButton}
                alt={"Black box with Apple logo on the left and text on the right that says Download on the Apple Store"}
              />
            </a>
          </div>
        </div>
      </LandingSection>
      <LandingSection
        padded={false}
        className={"pt-10"}
      >
        <Typography
          variant="h2"
          className="text-center mb-5 text-xl md:text-3xl font-motley"
        >
          Meet some of our Customers
        </Typography>

        <div
          className="flex flex-wrap items-center justify-around gap-5 *:bg-tempo-black *:h-auto"
        >
          <img
            src={logoMsstate}
            alt={[
              "Capital M with a curved ribbon in the center with the word 'State' with a vertical white separator with",
              "the word Mississippi State University on the right"
            ].join(" ")}
            width={600}
          />
          <img 
            src={logoHopsonLawFirm}
            alt={[
              "Blue Banner with the words Hopson Law Group above a blue Eagle crest with two swords behind and a shield", 
              "in front with the words Life Liberty Resources Happiness that is holding a banner with its claws", 
              "with the words Defending Freedom & Fighting Injustice"
            ].join(" ")}
            width={400}
            />
        </div>
      </LandingSection>
      <LandingSection
        padded={true}
        className="flex"
      >
        <div
          className="flex flex-col justify-center mb-5 md:mb-0 md:p-4 md:w-1/2"
        >
          <Typography
            variant="h2"
            className={[
              "bg-tempo-black text-tempo-tan text-3xl md:text-5xl font-motley",
              "plug-text-shadow-[-1px_-1px_4px_#E15F2B,1px_1px_4px_#22A7F0] "
            ].join(" ")}
          >
            Our <strong>Short-Form Content</strong> enhances your learning experience
          </Typography>
          <p
            className="bg-tempo-black font-light"
          >
            Create 60-second quick-start guides!
            Quickly onboard and train team members,
            introduce customers to a new product,
            or teach students about a new subject!
          </p>
        </div>

        <ul
          className="bg-tempo-black text-lg md:w-1/2 list-style-none"
        >
          <li
            className="mb-10"
          >
            <Typography
              variant="h3"
              className="text-xl md:text-2xl"
            >
              Streamlined Creation
            </Typography>
            <p
              className="mt-3 md:mt-0 text-lg font-light md:leading-8"
            >
              No need for professional equipment or teams!<br />
              Create your videos in just a few seconds with nothing but your phone.<br />
              Shoot it like a TikTok&reg; and get your members learning quickly!
            </p>
          </li>
          <li
            className="mb-10"
          >
            <Typography
              variant="h3"
              className="text-xl md:text-2xl"
            >
              Reduced Learning Time
            </Typography>
            <p
              className="mt-3 md:mt-0 text-lg font-light ,d:leading-8"
            >
              Learning shouldn't take weeks!<br/>
              With short-form content, members learn 70% faster,<br /> 
              so you're wasting less time onboarding and more time doing!
            </p>
          </li>
          <li
            className="mb-10"
          >
            <Typography
              variant="h3"
              className="text-xl md:text-2xl"
            >
              Simplified Replayability
            </Typography>
            <p
              className="mt-3 md:mt-0 text-lg font-light md:leading-8"
            >
              Learning is all about repetition!<br />
              Members of your Moonbase can quickly rewatch guides and find 
              important information without having to sit through hour-long videos
            </p>
          </li>
        </ul>
      </LandingSection>
      <LandingSection
        className={[
          "relative bg-tempo-black mx-auto my-5 border-4 border-tempo-orange rounded-2xl px-5 py-5 w-[75vw]",
          "shadow-tempo-purple shadow-[0_0_12px_5px,inset_0_0_12px_5px]",
        ].join(" ")}
      >
        <Typography
          className={[
            "absolute top-[-20px] md:top-[-32px] left-[10%] bg-tempo-black rounded-md",
            "shadow-[-20px_0_1px_0,20px_0_1px_0] shadow-tempo-black",
            "plug-text-shadow-[-1px_-1px_3px_#E15F2B,2px_2px_10px_#551F6E]",
            "text-2xl md:text-5xl font-motley"
          ].join(" ")}
          variant="h2"
        >
          <strong>AI</strong>-Powered Learning
        </Typography>
        <ul
          className="flex flex-wrap items-center justify-evenly gap-y-4"
        >
          <Card
            className="shadow-[1px_1px_12px_2px] shadow-tempo-blue border-2 border-tempo-blue w-60 md:w-72 h-fit"
          >
            <CardHeader
              variant="gradient"
              color="orange"
              className="mt-5 border-2 border-tempo-purple"
            >
              <img
                src={aiTutor}
                alt="NEED TO DESCRIBE"
              />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h3"
                className="mb-2 uppercase text-2xl"
              >
                AI Tutor
              </Typography>
              <Typography>
                With our AI tutor Aiva, get your questions answered immediately!<br />
                Aiva uses information from your Guides or online to answer all your questions.
              </Typography>
            </CardBody>
            <CardFooter
              className="text-center"
            >
              <NavButton
                to="/ai-tutor"
                label="Learn More"
                className="text-center"
              />
            </CardFooter>
          </Card>
          <Card
            className="shadow-[1px_1px_12px_2px] shadow-tempo-blue border-2 border-tempo-blue w-60 md:w-72 h-fit"
          >
            <CardHeader
              variant="gradient"
              color="orange"
              className="mt-5 border-2 border-tempo-purple py-5"
            >
              <img
                src={aiGuideGen}
                alt="Need to describe"
              />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h3"
                className="mb-2 uppercase text-2xl"
              >
                Guide Generator
              </Typography>
              <Typography>
                Coming up with content can be hard, so let our AI do it for you!<br />
                Quickly get an outline for your Guide broken down into Units, complete with video ideas and scripts.
              </Typography>
            </CardBody>
            <CardFooter
              className="text-center"
            >
              <NavButton
                to="/ai-tutor"
                label="Learn More"
                className="text-center"
              />
            </CardFooter>
          </Card>
          <Card
            className="shadow-[1px_1px_12px_2px] shadow-tempo-blue border-2 border-tempo-blue w-60 md:w-72 h-fit"
          >
            <CardHeader
              variant="gradient"
              color="orange"
              className="mt-5 border-2 border-tempo-purple pb-5"
            >
              <img
                src={aiQuizGen}
                alt="need to describe"
              />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h3"
                className="mb-2 uppercase text-2xl"
              >
                Quiz Generator
              </Typography>
              <Typography>
                Guarantee that your members are learning with quizzes!<br/>
                Our AI automatically builds quizzes for you after your Guide has been uploaded.
              </Typography>
            </CardBody>
            <CardFooter
              className="text-center"
            >
              <NavButton
                to="/ai-tutor"
                label="Learn More"
                className="text-center"
              />
            </CardFooter>
          </Card>
        </ul>
      </LandingSection>
      <LandingSection
        className={[
          "flex relative overflow-hidden shadow-tempo-purple shadow-[inset_0_0_100px_10px] bg-tempo-black h-[700px]"
        ].join(" ")}
      >
        <div 
          className={[
            "absolute z-0 top-[70%] md:top-[5%] left-[40%] md:left-[10%]",
            "w-[300px] h-[300px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[8%] md:top-[50%] left-[-10%] md:left-[5%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[4%] md:top-[65%] left-[0%] md:left-[15%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[1%] md:top-[55%] left-[10%] md:left-[30%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[-1%] md:top-[35%] left-[20%] md:left-[35%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[-1%] md:top-[60%] left-[30%] md:left-[80%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        <div 
          className={[
            "absolute z-0 top-[3%] md:top-[10%] left-[40%] md:left-[90%]",
            "w-[100px] h-[100px] rounded-[50%] shadow-md shadow-tempo-black",
            "bg-gradient-to-br from-tempo-blue from-20% to-tempo-orange to-45%",
          ].join(" ")}
        />
        
        <div
          className="md:absolute top-[20%] left-[50%] z-[1] self-center p-10 text-center"
        >
          <Typography
            variant="h2"
            className="text-2xl md:text-5xl font-motley"
          >
            Train Any Audience
          </Typography>
          <p
            className="my-5 font-light"
          >
            Learning is more than just consuming content.<br />
            With tailor-made courses, train everyone all under one app.
          </p>

          <NavButton
            to={"/solutions"}
            label={"Learn More"}
            className={"hover:bg-tempo-white hover:text-tempo-orange"}
          />
        </div>
      </LandingSection>
      <LandingSection
        padded={true}
      >
        <div
          className={[
            "relative shadow-tempo-orange shadow-[40px_20px] bg-tempo-white mb-[150px] rounded-[20px] mx-auto p-[50px] w-[80vw] text-tempo-black text-center",
            "after:content-['.'] after:block after:absolute after:bottom-[-75px] after:shadow-tempo-orange after:shadow-[30px_15px] after:bg-tempo-gray after:rounded-[11px]",
            "after:w-[30%] after:h-9 after:text-tempo-white after:text-left after:plug-text-shadow-[10px_10px_#E15F2B] after:leading-[30px] after:text-[200px]"
          ].join(" ")}
        >
          <Typography
            variant="h2"
            className="text-2xl md:text-5xl font-motley"
          >
            Keep the conversation going
          </Typography>
          <p
            className="my-5"
          >
            No one should ever learn alone!<br />
            Easily chat with your members through Groups within your organization.<br />
            Let your members meet peers to foster discussion in Groups.
          </p>
          <NavButton
            to={"/"}
            label={"Learn More"}
          />
        </div>

        <div
          className="flex mb-[200px] md:mb-0"
        >
          <div
            className="mb-[20px] md:mb-0 w-[20%]"
          >
            <div
              className={[
                "shadow-tempo-orange shadow-[5px_10px_1px_0] bg-tempo-white rounded-[50%_0_50%_50%] w-[80px] h-[80px]",
                "before:block before:relative before:top-[-40px] before:left-[-80px] before:w-[100px] before:h-[40px] before:rotate-[40deg]",
                "before:bg-tempo-blue before:bg-repeat before:bg-[length:10px_10px] before:bg-[linear-gradient(to_right,_#E2E2E2_2px,_transparent_1px),_linear-gradient(to_bottom,_#E2E2E2_3px,_transparent_2px)]",
                "after:block after:relative after:left-[60px] after:bottom-[-40px] after:w-[100px] after:h-[40px] after:rotate-[40deg]",
                "after:bg-tempo-blue after:bg-repeat after:bg-[length:10px_10px] after:bg-[linear-gradient(to_right,_#E2E2E2_2px,_transparent_1px),_linear-gradient(to_bottom,_#E2E2E2_3px,_transparent_2px)]"
              ].join(" ")}
            />
          </div>
          <div
            className={[
              "relative top-[100px] md:top-0 left-[-75px] shadow-tempo-orange shadow-[3px_5px_1px] bg-tempo-white rounded-[10px] p-[20px] md:py-[50px] w-fit h-min text-tempo-black leading-[40px]",
              "before:content-['.'] before:inline-block before:absolute before:top-[-50px] before:left-[-10px] before:rotate-[40deg]",
              "before:text-tempo-white before:plug-text-shadow-[5px_1px_1px_#E15F2B] before:text-[150px]"
            ].join(" ")}
            >
            <img
              src={logoMsstate}
              alt={[
                "Capital M with a curved ribbon in the center with the word 'State' with a vertical white separator with the word Mississippi State University on the right"
              ].join(" ")}
              width={300}
              height={30}
              className={"mb-10"}
              style={{filter: "invert(100%)"}}
            />
            <Typography
              variant="h3"
              className="text-center text-2xl font-light"
            >
              Moonbase has been a great asset to our learning process!
            </Typography>
          </div>
        </div>
      </LandingSection>
      <LandingSection>
        <div
          className="bg-tempo-black mb-10 p-[20px] text-center"
        >
          <Typography
            variant="h2"
            className="mb-5 text-3xl md:text-5xl font-motley"
          >
            Shooting for the stars is easier with Moonbase
          </Typography>
          <NavButton
            to={"/book-demo"}
            label={"Book a Demo"}
          />
        </div>
      </LandingSection>
    </>
  );
}

export default MoonbaseLanding;