import { create } from 'zustand';

const useOnboardingStore = create((set) => ({
    personalInformation: { firstName: null, lastName: null, orgName: null, orgDesc: null },
    signInDetails: { emailAddress: null, password: null },
    selectedPackage: { index: null, details: null },
    organizationInfo: { organizationId: null, icon: null, orgName: null, orgDescription: null },
    updatePersonalInformation: (personalInformation) => set(() => ({ personalInformation: personalInformation })),
    updateSigninDetails: (signInDetails) => set(() => ({ signInDetails: signInDetails })),
    updateSelectedPackage: (index, details) => set(() => ({ selectedPackage: { index, details } })),
    updateOrganizationInfo: (organizationInfo) => set(() => ({ organizationInfo })),
}));

export default useOnboardingStore;
