import React from "react";

import {
  ThemeProvider,
  Tabs,
  TabsHeader,
  Tab
} from "@material-tailwind/react";

const FormSwitch = ({
  className,
  values, setState
}) => {
  const switchTheme = {
    tabsHeader: {
      styles: {
        base: {
          bg: "bg-tempo-black",
          bgOpacity: "bg-opacity-100",
        }
      },
    },
    tab: {
      styles: {
        base: {
          tab: {
            initial: {
              color: "text-tempo-white",
              fontFamily: "font-motley",
            },
          },
          indicator: {
            bg: "bg-tempo-orange",
          },
        },
      },
    },
  };
  
  return (
    <>
      <ThemeProvider value={switchTheme}>
        <Tabs
          className={[
            className,
            "self-center w-1/2 lg:w-1/4"
          ].join(" ")}
          value={0}
        >
          <TabsHeader>
            {values.map((val, index) => {
              return (
              <Tab
                value={index}
                className="text-md"
                onClick={() => setState(index)}
                key={index}
              >
                {val}
              </Tab>
              );
            })}
          </TabsHeader>
        </Tabs>
      </ThemeProvider>
  </>
  );
}

export default FormSwitch;