import { Dialog, DialogBody, Spinner } from "@material-tailwind/react";

const LoadingSpinner = ({
    animate, className,
    dialogOpen, dialogOpenHandler
  }) => {
  return <Dialog       
    open={dialogOpen} 
    handler={dialogOpenHandler}
    size="xs"
    dismiss={{ enabled: false }}
    animate={animate}
    className={[className, "bg-white flex justify-center"].join(" ")}

    aria-modal={true}
  >
    <DialogBody>
      <Spinner color="orange" className="w-12 h-12" />
    </DialogBody>
  </Dialog>
}

export default LoadingSpinner;