import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";

import { signupRequest } from "../../services/authenticationService";

import {
  Typography,
  Button
} from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputRow from "../../components/form/inputRow";
import InputField from "../../components/form/inputField";
import PasswordField from "../../components/form/passwordField";
import CheckboxField from "../../components/form/checkboxField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import FormImage from "../../components/form/formImage";

import loginSVG from "../../assets/images/illustration-login.svg";

const LoginInformation = ({ 
  authenticated, personalInfo, defaultData, updateStore, 
  toggleDialog, onSubmit
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const signOut = useSignOut();
  const navigate = useNavigate();

  /* TODO: Check when to toggle the loading dialog when switching between or submitting forms 
  *  These currently close the dialog when coming from personalInformation Form
  */
  toggleDialog();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [email, password, confirmPassword] = e.target;
    const signInDetails = { 
      emailAddress: email.value,
      password: password.value
    };

    if (authenticated) { onSubmit(); } 
    else {
      if (password.value !== confirmPassword.value) {
        setErrorMessage("Password mismatch");
        return;
      } else {
        setErrorMessage("");
      }

      toggleDialog();
      try {
        const { firstName, lastName } = personalInfo || {};

        const signupResult = await signupRequest(firstName, lastName, signInDetails.emailAddress, signInDetails.password);
        toggleDialog();

        const { success, message } = signupResult;
        if (success && message !== "User already exists") {
          if (typeof (message) !== "string") {
              const { userId } = message.user ?? {};
              signInDetails.userId = userId;
          }
          updateStore(signInDetails);
          toggleDialog()
          onSubmit();
        } else {
          toggleDialog();
          if (message === "User already exists") {
            setErrorMessage("User already exists, please login instead");
          } else {
            setErrorMessage(message);
          }
        }

      } catch (e) {
        toggleDialog();
        setErrorMessage(e.toString());
      }
    }
  }

  const doSignOut = () => {
    signOut();
    navigate(0)
  }

  return <> 
    <AuthForm 
      tagline={"Set up your Moonbase Account"} 
      handleSubmit={handleSubmit}
    >
      <InputField 
        label="Email"
        placeholder={"Email"}
        defaultValue={defaultData.emailAddress}
        disabled={authenticated}
        required={true}
        type={"email"} 
      />
      {!authenticated && 
        <>
          <InputRow>
            <PasswordField
              minLength={8}
              required={true}
            />
            <PasswordField
              label="Confirm Password"
              placeholder={"Confirm Password"}
              minLength={8}
              required={true} 
            />
          </InputRow>
      
          <CheckboxField 
            required={true} 
            label={
              <Typography
                variant="small"
                className="text-left"
              >
                I acknowledge that I have read and agree with the
                <a 
                  className="text-tempo-orange whitespace-nowrap"
                  href="https://moonbase.biz/pages/privacy/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Terms and Conditions&nbsp;
                </a>
                of using Moonbase app.
              </Typography>
            } 
          />

          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
        </>
      }
      {authenticated &&
        <>
          <Typography
            className="mb-10 font-normal"
          >
            You're already logged in, please click the button below to proceed
          </Typography>
          <Typography
            variant="small"
          >
            Would you like to use a different account? 
            <Button
              variant="text"
              color="deep-orange"
              onClick={doSignOut}
            >
              Logout
            </Button>
          </Typography>
        </>
      }

      <FormSubmit>
        Next Step
      </FormSubmit>
    </AuthForm>
    <FormImage 
      src={loginSVG}
      alt={
        "Light-skinned lady with medium-length hair wearing an orange shirt and black pants posing with hands on hip and one leg up " +
        "in front of a person-sized black laptop displaying a card replicating a post with an orange circle with a lock in the upper right "
      }
    />
  </>
}

export default LoginInformation;