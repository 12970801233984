import { Link } from "react-router-dom";

const NavButton = ({
    to, label,
    className
}) => {
  return (
    <Link
      to={to}
      className={[
        className,
        "inline-flex items-center justify-center shadow-[1px_1px_5px] shadow-tempo-black bg-tempo-orange rounded-lg p-4 h-10",
        "text-tempo-white text-md text-center font-motley whitespace-nowrap",
        "hover:shadow-[1px_1px_5px,1px_1px_10px] hover:shadow-tempo-purple"
      ].join(" ")}
    >
      {label}
    </Link>
  );
}

export default NavButton;