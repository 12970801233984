import { useState } from "react";
import { Outlet } from "react-router-dom";

import LoadingSpinner from "../../components/form/loadingSpinner";

import moonbaseIcon from "../../assets/images/moonbase_icon.png";

const AuthenticationLayout = () => {
  const [title, setTitle] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogOpenHandler = () => setDialogOpen(!dialogOpen);

  return ( 
    <div 
      id="main-pane" 
      className="bg-tempo-black pb-6 min-h-screen w-screen bg-accent"
    >
      <header 
        className="flex gap-x-2 items-center p-3 w-full"
      >
        {/* TODO: When logo has been created, move h2 title to h1  */}
        <img 
          className="rounded-lg object-cover"
          src={moonbaseIcon}
          alt="Moonbase Icon with a yellow-to-orange gradient background that has an orange moon in front with craters and a white atmosphere
            that has the letter M in front with hyperrealistic tech artifacts adorning the surface"
          height={50} 
          width={50} 
        />
        <h1
          className="bg-tempo-black text-tempo-white font-motley text-3xl"
        >
          {'Moonbase'} 
        </h1>
        <h2
          className="hidden bg-tempo-black text-tempo-white font-motley text-3xl md:inline-block"
          >
            {title.toUpperCase()}
        </h2>
      </header>
      <main 
        className="shadow-lg shadow-tempo-blue bg-white mx-auto rounded-lg w-fit"
      >
        <LoadingSpinner 
          dialogOpen={dialogOpen} 
          dialogOpenHandler={dialogOpenHandler} 
        />
        <div 
          className="p-2 md:p-5 max-w-full text-center font-poppins"
        >
          <Outlet 
            context={{
              dialogOpen, setDialogOpen,
              title, setTitle
            }} 
          />
        </div>
      </main>
    </div>
  );
};

export default AuthenticationLayout;