import {
  Card,
  Typography,
} from "@material-tailwind/react";
import { QRCode } from "react-qrcode-logo";

const DownloadApp = () => {
  const storeUrl = "https://web.moonbase.biz/download-app";
  return (
    <>
      <div
        className="absolute md:h-full"
      >
        <div
          className="absolute top-0 left-0 z-[1] bg-gradient-to-b md:bg-gradient-to-r from-tempo-black w-full md:w-[60%] h-[60%] md:h-full"
        />
      </div>

      <div
        className="relative z-[1] px-[8.33%] pt-[30px] h-full md:h-auto"
      >
        <div>
          <Typography
            variant="h1"
            className={[
              "my-10 md:my-20 text-tempo-orange text-[50px] md:text-[130px] font-motley",
              "plug-text-shadow-[1px_1px_10px_#551F6E,_0_0_5px_#F3F3F3,_0_0_20px_#1F1F1F]"
            ].join(" ")}
          >
            Moonbase
          </Typography>
          <Typography
            variant="h2"
            className="text-3xl md:text-5xl font-motley plug-text-shadow-[0_0_20px_#1F1F1F,_0_0_5px_#F3F3F3]"
          >
            Download App<br />
          </Typography>
          <p
            className="my-5 md:w-[80%] text-[16px] md:text-[18px] plug-text-shadow-[0_0_20px_#1F1F1F,_0_0_3px_#F3F3F3]"
          >
            Get started on this amazing journey by downloading the Moonbase app<br />
            Just scan the QR code below and you're good to go!
          </p>
          <Card style={{ width: 'min-content' }} className="rounded-md">
            <QRCode bgColor="#00000000" size={250} value={storeUrl} fgColor="black" eyeRadius={10} eyeColor={'#551F6E'} />
          </Card>
        </div>


      </div>
    </>
  );
}

export default DownloadApp;