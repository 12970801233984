const BottomLinks = ({
  children
}) => {
  return (
    <div className="flex justify-between mt-5 text-tempo-orange">
      {children}
    </div>
  );
}

export default BottomLinks;