import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { forgotPasswordRequest } from "../../services/authenticationService";

import { Card } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import BottomLinks from "../../components/form/bottomLinks";

const ForgotPasswordForm = ({title}) => {
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();

  // sets title of form in authentication layout
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [email] = e.target;
    try {
      setDialogOpen(true);
      const forgotPasswordResult = await forgotPasswordRequest(email.value);
      setDialogOpen(false);

      const { success, message } = forgotPasswordResult;
      if (success) {
        navigate(`/auth/reset-password${search}`, { state: { emailAddress: email.value } });
      } else {
        setErrorMessage(message);
      }
    } catch (e) {
      setDialogOpen(false);
      setErrorMessage(e.toString());
    }
  }

  return <Card shadow={false}>
    <AuthForm tagline="Forgot Password?" handleSubmit={handleSubmit}>
      <InputField label="Email" placeholder={"Email"} type={"email"} required={true} />

      <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
      <FormSubmit>Send Password Reset Email</FormSubmit>
    </AuthForm>

    <BottomLinks>
      <Link to={`/auth${search}`}>Back to Login</Link>
    </BottomLinks>
  </Card>
}

export default ForgotPasswordForm;