import { useState } from "react";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import {
  changeEmailAddressRequest,
  loginRequest,
  resendVerificationEmailRequest,
  verifyEmailAddressRequest
} from "../../services/authenticationService";
import { createOrganizationRequest } from "../../services/organizationService";

import {
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  Button
} from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputRow from "../../components/form/inputRow";
import InputField from "../../components/form/inputField";
import ErrorMessage from "../../components/form/errorMessage";
import SuccessMessage from "../../components/form/successMessage";
import FormSubmit from "../../components/form/formSubmit";
import FormImage from "../../components/form/formImage";

import verifyIllustration from "../../assets/images/illustration-verification.svg";

const EmailVerification = ({ 
    authenticated = false, signInDetails, updateEmailInState, toggleDialog, signIn, orgInfo, authenticatedUser, updateOrganizationInfo, onSubmit
  }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [verified, setVerified] = useState(authenticated);
  const [signedIn, setSignedIn] = useState(authenticated);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [changingEmail, setChangingEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [changeEmailErrorMessage, setChangeEmailErrorMessage] = useState(null);
  const [changeEmailSuccessMessage, setChangeEmailSuccessMessage] = useState(null);
  const [newEmail, setNewEmail] = useState(signInDetails.emailAddress);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
 
  const authHeader = useAuthHeader();
    
  const changeEmail = async () => {
    if (editingEmail) {
      setChangingEmail(true);
      setChangeEmailSuccessMessage(null);
      setChangeEmailErrorMessage(null);
      try {
        const result = await changeEmailAddressRequest(signInDetails?.emailAddress, signInDetails?.password, newEmail);
        const { success, message } = result;

        if (success) {
          setEditingEmail(false);
          setChangingEmail(false);
          if (updateEmailInState) updateEmailInState(newEmail);
          setChangeEmailSuccessMessage("Successfully updated");
        } else {
          setChangingEmail(false);
          setChangeEmailErrorMessage(message);
        }

      } catch (e) {
        setChangingEmail(false);
        setChangeEmailErrorMessage(e.toString());
      }
    } else {
      setEditingEmail(true);
    }
  }

  const verifyEmailAddress = async () => {
    if (!verificationCode) {
      setErrorMessage("Please enter a valid code");
      return;
    }
    toggleDialog();
    try {
      const signupResult = await verifyEmailAddressRequest(verificationCode);

      const { success, message } = signupResult;
      if (success) {
        toggleDialog();
        setVerified(true);
      } else {
        console.log(signupResult);
        if (typeof (signupResult) === "string") {
          if (signupResult === "email verified successfully!") {
            setVerified(true);
          } else {
            setErrorMessage(signupResult);
          }
        } else {
          setErrorMessage(message);
        }
      }
    } catch (e) {
      toggleDialog();
      setErrorMessage(e.toString());
    }
  }

  const login = async () => {
    try {
      setLoggingIn(true);
      const loginResult = await loginRequest(signInDetails.emailAddress, signInDetails.password);
      setLoggingIn(false);
      if (loginResult.success) {
        const { accessToken, userInfo } = loginResult;
        if (
          signIn({
            auth: {
              token: accessToken,
              type: 'Bearer'
            },
            refresh: accessToken,
            userState: userInfo
          })
        ) {
          setSignedIn(true); // logged in successfully
        } else {
          setLoginErrorMessage("Unable to login, please try again later");
        }
      } else {
        setLoginErrorMessage(loginResult.message || "An error occurred, please try again later");
      }
    } catch (e) {
      setLoggingIn(false);
      setLoginErrorMessage(e.toString());
    }
  }

  const doVerification = async () => {
    if (verificationCode) {
      verifyEmailAddress();
      setErrorMessage(null);
    } else {
      //resend verification email
      try {
        setResendingEmail(true);
        await resendVerificationEmailRequest(signInDetails.emailAddress);
        setResendingEmail(false);
      } catch (e) {
        setResendingEmail(false);
        setErrorMessage(e.toString());
      }
    }
  }

  // create organization
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toggleDialog();
      const orgCreationResult = await createOrganizationRequest(authHeader, orgInfo?.orgName, orgInfo?.orgDesc);
      toggleDialog();
      const { success, data, message } = orgCreationResult;
      if (success) {
        updateOrganizationInfo(data);
        onSubmit();
      } else setErrorMessage(message || data?.message || "Unable to create your organization at the moment, please try again shortly")
    } catch (e) {
      toggleDialog();
      setErrorMessage(e.toString());
    }
  }

  return (
  <>
    <Dialog
      open={!signedIn && verified}
      dismiss={{ enabled: false }}
    >
      <DialogHeader 
        className="flex justify-center"
      >
        <Typography
          variant="h2"
        >
          Account Ready!
        </Typography>
      </DialogHeader>
      <DialogBody 
        className="text-center"
      >
        <Typography>
          Your account has been set up, you are ready for the next step!
          <br/>Click the button below to continue
        </Typography>
        <ErrorMessage>
          {loginErrorMessage}
        </ErrorMessage>
        <Button
          color="orange"
          className="mt-7"
          disabled={loggingIn}
          onClick={login}
        >
          Continue
        </Button>
      </DialogBody>
    </Dialog>
    <AuthForm
      tagline={"Email Verification"}
      handleSubmit={handleSubmit}
    >
      <Typography
        variant="small"
      >
        {authenticated ?
          "You account is already verified, please continue to the next step" : 
          "A verification code has been sent to your email address. Copy and paste the code in the form below."
        }
      </Typography>

      <InputRow>
        <InputField 
          label="Email"
          placeholder={"Email"}
          defaultValue={signInDetails.emailAddress}
          disabled={!editingEmail}
          required={true}
          type={"email"}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <Button 
          variant="filled"
          color={editingEmail ? "green" : "orange"}
          disabled={(changingEmail || authenticated) || (verified)}
          onClick={changeEmail}
        >
          {editingEmail ? "Confirm" : "Change"}
        </Button>
      </InputRow>
      {changeEmailErrorMessage && 
        <ErrorMessage>
          {changeEmailErrorMessage}
        </ErrorMessage>
      }
      {changeEmailSuccessMessage && 
        <SuccessMessage>
          {changeEmailSuccessMessage}
        </SuccessMessage>
      }

      {!authenticated &&
        <>
          <InputRow>
            <InputField 
              label="Verification Code" placeholder={"Enter code"}
              disabled={verified} required={true} type={"text"} 
              onChange={(e) => { setVerificationCode(e.target.value) }} 
            />
            <Button 
              variant={verificationCode ? "filled" : "outlined"} color="deep-orange"
              disabled={((!verificationCode) && verified) || verified || resendingEmail}
              onClick={doVerification}
            >
              {verificationCode ? "Verify" : "Resend"}
            </Button>
          </InputRow>
          <ErrorMessage>
            {errorMessage ?? ""}
          </ErrorMessage>
        </>
      }

      <FormSubmit
        variant={verified && (authenticated || signedIn) ? "filled" : "outlined"}
        disabled={!verified || !(authenticated || signedIn)}
      >
        Next Step
      </FormSubmit>
    </AuthForm>
    <FormImage
      src={verifyIllustration}
      alt={
        "Light-skinned man wearing a gray shirt and black pants holding onto the side of a yellow, person-sized document drawer " +
        "containing a document with three orange lines and six gray lines replicating text with a orange checkmark bubble on the " +
        "upper right"
      }
    />
  </>
  );
}

export default EmailVerification;