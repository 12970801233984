import { useState } from "react";

import InputField from "./inputField";

import { EyeIcon } from "@heroicons/react/16/solid";
import { EyeSlashIcon } from "@heroicons/react/16/solid";

const PasswordField = ({
  label, error, success, labelProps, containerProps, className, shrink, inputRef,
  defaultValue, disabled, minLength, onChange, placeholder, required
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <InputField
      label={label === undefined ? "Password" : label}
      placeholder={placeholder === undefined ? "Password" : placeholder}
      type={visible ? "text" : "password"}
      icon={visible ?
        <EyeSlashIcon onClick={() => setVisible(false)} /> : 
        <EyeIcon onClick={() => setVisible(true)} />
      }

      error={error}
      success={success}
      labelProps={labelProps}
      containerProps={containerProps}
      className={className}
      shrink={shrink}
      inputRef={inputRef}
      
      defaultValue={defaultValue}
      disabled={disabled}
      minLength={minLength}
      required={required}
      onChange={onChange}
    />
  );
}

export default PasswordField;