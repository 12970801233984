import React from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardBody,
  Typography
} from "@material-tailwind/react";

const PlanCard = ({
  e, index, displayPrice,
  selectedPlan, setSelectedPlan
}) => {
  
  return (
    <>
      <Card
        color="gray"
        variant="gradient"
        className={[
          "w-20 lg:min-w-[14rem] p-1 pt-2 lg:p-8 cursor-pointer",
          "hover:scale-[1.02] hover:shadow-tempo-purple hover:shadow-lg"
        ].join(" ")}
        key={index}
        onClick={() => setSelectedPlan(index)}
      >
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="relative m-0 lg:mb-8 rounded-none lg:border-b lg:border-white/10 pb-6 lg:py-8 text-left lg:text-center"
        >
          <Typography
            variant="h3"
            className="text-tempo-white uppercase font-normal text-sm lg:text-3xl"
          >
            {e.name}
          </Typography>
          <Typography
            variant="h4"
            color="white"
            className="hidden lg:flex justify-center gap-1 mt-6 text-lg text-4xl"
          >
            <span
              className="text-xl"
            >
              $
            </span>

            {displayPrice === 0 ? e.pricePerMonth : e.pricePerYear}

            <span
              className="lg:self-end text-sm lg:text-xl"
            >
              {displayPrice === 0 ? "/mo" : "/yr"}
            </span>          
          </Typography>
          <Typography
            variant="small"
            className="hidden lg:block mt-4 text-center text-tempo-gray font-normal"
          >
            {e.description}
          </Typography>
          {index === selectedPlan ?
            <CheckIcon
              className="absolute lg:top-0 right-0 bottom-0 rounded-full border border-tempo-white bg-tempo-green size-4 lg:size-6"  
            /> :
            <CheckIcon
              color="white"
              className="absolute lg:top-0 right-0 bottom-0 rounded-full border border-tempo-white bg-tempo-gray size-4 lg:size-6"
            />
          }
        </CardHeader>
        <CardBody
          className="p-0 hidden lg:block"
        >
          <ul
            className="flex flex-col gap-4"
          >
            {e.perks.map((perk, id) => {
              return (
                <li
                  className="flex items-center gap-4"
                  key={id}
                >
                  <span
                    className="rounded-full border border-tempo-black bg-tempo-green p-1"
                  >
                  </span>
                  <Typography
                    variant="small"
                    className="font-normal text-left"
                  >
                    {perk}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </CardBody>
      </Card>
    </>
  );
}

export default PlanCard;