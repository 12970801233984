import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import ErrorMessage from "../../components/form/errorMessage";
import TextAreaField from "../../components/form/textAreaField";
import FormSubmit from "../../components/form/formSubmit";

const ContactForm = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const [email, password] = e.target;

    try {

    } catch (e) {
      
    }
    /*
    let jsonData = validateForm();
    let apiEndpoint = "https://devapi.moonbase.biz/email/send-support-email";
    let xhr = new XMLHttpRequest();
    
    xhr.open("POST", apiEndpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
        if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
            form.reset();
            form.style.display = "none";
            document.getElementById("form-success").innerText = "Thank you, we will get back with you as soon as possible.";
        }
        else if (xhr.readyState == XMLHttpRequest.DONE && xhr.status !== 200) {
            alert("Something went wrong. Please try again. Error:" + xhr.statusText );
        }
    };

    xhr.send(jsonData);
    */
  }
  // TODO: Add Google recaptcha
  
  return (
    <AuthForm
      tagline="Contact Support"
      handleSubmit={handleSubmit}
      className={"md:mx-[8.33%] my-[5%] p-10"}
    >
      <InputField label="Name" placeholder={"Name"} type={"text"} required={true} />
      <InputField label="Email" placeholder={"Email"} type={"email"} required={true} />
      <InputField label="Subject" placeholder={"Subject"} type={"text"} required={true} />
      <TextAreaField
        label="Message"
        required={true}
      />
      
      <ErrorMessage>{/*apiError ??*/ ""}</ErrorMessage>
      <FormSubmit>Send Message</FormSubmit>
    </AuthForm>
  );
}

export default ContactForm;

/*
function validateForm() {
  let name = document.getElementById("name").value;
  let email = document.getElementById("email").value;
  let subject = document.getElementById("subject").value;
  let message = document.getElementById("message").value;

  let reqData = {
      name: sanitizeText(name),
      email: email,
      subject: sanitizeText(subject),
      message: sanitizeText(message),
  };
  return JSON.stringify(reqData);
}
*/