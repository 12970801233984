import { Button } from "@material-tailwind/react";

const FormSubmit = ({
    className, children, loading,
    disabled, 
  }) => {
  return <Button
    variant="gradient"
    size="md"
    color="orange"
    fullWidth={true}
    ripple={false}
    className={className}
    loading={loading}
    
    type="submit"
    disabled={disabled}
  >
    {children}
  </Button>
}

export default FormSubmit;