import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { changeEmailAddressRequest, resendVerificationEmailRequest, verifyEmailAddressRequest } from "../../services/authenticationService";

import { Button, Card, Typography } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import ErrorMessage from "../../components/form/errorMessage";
import SuccessMessage from "../../components/form/successMessage";
import FormSubmit from "../../components/form/formSubmit";

const VerifyAccountForm = ({title, emailAddress, password }) => {
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();
  const [verificationCode, setVerificationCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [changingEmail, setChangingEmail] = useState(false);
  const [changeEmailErrorMessage, setChangeEmailErrorMessage] = useState(null);
  const [changeEmailSuccessMessage, setChangeEmailSuccessMessage] = useState(null);
  const [newEmail, setNewEmail] = useState(emailAddress);

  // sets title of form in authentication layout
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])  
  
  const navigate = useNavigate();
  const { search, state } = useLocation();

  emailAddress = emailAddress || state?.emailAddress;
  password = password || state?.password;
  const resendVerification = state?.resendVerification || false;

  const changeEmail = async () => {
    if (editingEmail) {
      setChangingEmail(true);
      setChangeEmailSuccessMessage(null);
      setChangeEmailErrorMessage(null);

      try {
        const result = await changeEmailAddressRequest(emailAddress, password, newEmail);
        const { success, message } = result;
        if (success) {
          setEditingEmail(false);
          setChangingEmail(false);
          emailAddress = newEmail;
          setChangeEmailSuccessMessage("Successfully updated");
        } else {
          setChangingEmail(false);
          setChangeEmailErrorMessage(message);
        }
      } catch (e) {
          setChangingEmail(false);
          setChangeEmailErrorMessage(e.toString());
        }
    } else {
      setEditingEmail(true);
    }
  }

  const verifyEmailAddress = async () => {
    if (!verificationCode) {
      setErrorMessage("Please enter a valid code");
      return;
    }
    setDialogOpen(true);
    try {
      const signupResult = await verifyEmailAddressRequest(verificationCode);
      setDialogOpen(false);
      const { success, message } = signupResult;
      if (success) {
        setVerified(true);
      } else {
        console.log(signupResult);
        if (typeof (signupResult) === "string") {
          if (signupResult === "email verified successfully!") {
            setVerified(true);
          } else {
            setErrorMessage(signupResult);
          }
        } else {
          setErrorMessage(message);
        }
      }
    } catch (e) {
      setDialogOpen(false);
      setErrorMessage(e.toString());
    }
  }

  const doVerification = async () => {
    if (verificationCode) {
      verifyEmailAddress();
    } else {
      resendEmail() //resend verification email
    }
  }

  const resendEmail = useCallback(async () => {
    try {
      setResendingEmail(true);
      await resendVerificationEmailRequest(emailAddress);
      setResendingEmail(false);
    } catch (e) {
      setResendingEmail(false);
      setErrorMessage(e.toString());
    }
  }, [emailAddress]);

  const login = () => { navigate(`/auth${search}`) }

  useEffect(() => {
    if (resendVerification) resendEmail();
  }, [resendVerification, resendEmail])

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  }

  return <Card shadow={false}>
    <AuthForm tagline="Email Verification" handleSubmit={handleSubmit}>
      <Typography variant="small" className="font-normal">
        A verification code has been sent to your email address. Copy and paste the code in the form below.
      </Typography>

      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
        <InputField label="Email" placeholder={"Email"} defaultValue={emailAddress} disabled={!editingEmail} required={true} type={"email"} 
          onChange={(e) => setNewEmail(e.target.value)} 
        />
        <Button variant={editingEmail ? "filled" : "outlined"} color="deep-orange" disabled={changingEmail}
          onClick={changeEmail}  
        >
          Change
        </Button>
      </div>
      {changeEmailErrorMessage && <ErrorMessage>{changeEmailErrorMessage}</ErrorMessage>}
      {changeEmailSuccessMessage && <SuccessMessage>{changeEmailSuccessMessage}</SuccessMessage>}

      <div className="flex flex-col md:flex-row gap-3 md:gap-6">
        <InputField label="Verification Code" placeholder={"Enter Code"} disabled={verified} required={true} type={"text"} 
          onChange={(e) => { setVerificationCode(e.target.value) }} 
        />
        <Button variant={verificationCode ? "filled" : "outlined"} color="deep-orange" 
          disabled={((!verificationCode) && verified) || verified || resendingEmail} 
          onClick={doVerification} 
        >
          {verificationCode ? "Verify" : "Resend"}
        </Button>
      </div>

      <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
      <FormSubmit disabled={!verified}>Verify Email</FormSubmit>
    </AuthForm>
  </Card>
}

export default VerifyAccountForm;