import { Checkbox } from "@material-tailwind/react"

const CheckboxField = ({ 
    label, icon, ripple, className, disabled, labelProps, iconProps, inputRef,
    required
  }) => {
  return (
    <Checkbox
      color="orange"
      label={label}
      icon={icon}
      ripple={ripple}
      className={className}
      disabled={disabled}
      containerProps={{
        className: "basis-16 md:basis-auto"
      }}
      labelProps={labelProps}
      iconProps={iconProps}
      inputRef={inputRef}

      required={required}
    />
  );
}

export default CheckboxField;