import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignOut from "react-auth-kit/hooks/useSignOut";

import { encryptText } from "../../services/encryptionService";

import { Button, Card, Typography } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import ErrorMessage from "../../components/form/errorMessage";

const MobileAppAuthForm = (props) => {
  //  TODO: Figure out how to pass in outlet context from grandfather layout component with AuthOutlet parent
  //const { setTitle } = useOutletContext();

  const [error, setError] = useState();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const encToken = searchParams.get("encToken");
  const logoutToken = searchParams.get("logoutToken");
  const action = searchParams.get("action");

  const authHeader = useAuthHeader();
  const openAppLink = "/app-auth/authorizeApp?token=";
  const logoutAppLink = "/app-auth/logout?token=";

  const openApp = useCallback(async (e) => {
    setError(null);
    if (encToken) {
      const encryptedToken = encryptText(encToken, authHeader);
      window.location.href = openAppLink + encryptedToken;
    } else {
      setError("An error occurred, please re-open this screen");
    }
  }, [openAppLink, authHeader, encToken, setError]);

  const doLogout = useCallback(() => {
    signOut();
    if (action === "logout" && logoutToken) {
      window.location.href = logoutAppLink + logoutToken;
    } else {
      navigate(0);
    }
  }, [signOut, navigate, action, logoutToken])

  useEffect(() => {
    if (action === "logout") {
      doLogout();
    }
  }, [doLogout, action])

  return <Card className="max-w-md" shadow={false}>
    <AuthForm tagline="You're all set up!">
      <Typography>Click the button below to open the app.</Typography>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button color="deep-orange" onClick={openApp}>LAUNCH APP</Button>
      <Button color="deep-orange" variant="text" onClick={doLogout}>Logout</Button>
    </AuthForm>
  </Card>
}

export default MobileAppAuthForm;