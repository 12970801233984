import React from "react";

import { Card } from "@material-tailwind/react";

const FormImage = ({
  className,
  src, alt
}) => {
  return (
    <Card 
      className="justify-center items-center hidden lg:flex" 
      shadow={false}
    >
      <img 
        className={className}
        src={src}
        alt={alt}
        width={400} 
        height={400} 
      />
    </Card>
  );
}

export default FormImage;