import CryptoJS from "crypto-js";

const encryptText = (key, text) => {
  const encrypted = CryptoJS.AES.encrypt(text, key).toString();
  return encrypted;
};

const decryptText = (key, encryptedText) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key).toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.error('Error decrypting:', error.message);
  }
};

export { encryptText, decryptText }