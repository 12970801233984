const LandingSection = ({
  padded,
  className, children
}) => {
  return (
    <section
      className={[
        className,
        padded === true ? "px-[5%] py-[8.33%]" : "p-0",
        "block flex-col md:flex-row justify-center md:justify-normal w-full text-lg font-poppins"
      ].join(" ")}
    >
      {children}
    </section>
  );
}

export default LandingSection;