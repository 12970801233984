import React from "react";

const InputRow = ({
  children
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-6">
      {children}
    </div>
  );
}

export default InputRow;