import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { resetPasswordRequest } from "../../services/authenticationService";

import { Card, CardBody } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import PasswordField from "../../components/form/passwordField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import BottomLinks from "../../components/form/bottomLinks";
import SuccessMessage from "../../components/form/successMessage";

const ResetPasswordPage = ({ title }) => {
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();
  const [passwordReset, setPasswordReset] = useState(false);

  // sets title of form in authentication layout
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  const navigate = useNavigate();
  const { search, state } = useLocation();
  const { emailAddress } = state || {};
  const handleSubmit = async (e) => {
    e.preventDefault();
    const [email, resetCode, newPassword, confirmPassword] = e.target;
    if (newPassword.value !== confirmPassword.value) {
      setErrorMessage("Passwords do not match");
    }
    try {
      setDialogOpen(true);
      const resetResult = await resetPasswordRequest(email.value, resetCode.value, newPassword.value, confirmPassword.value);
      setDialogOpen(false);
      const { success, message } = resetResult;
      if (success) {
        setPasswordReset(true);
      } else {
        setErrorMessage(message);
      }
    } catch (e) {
      setDialogOpen(false);
      setErrorMessage(e.toString());
    }
  }

  const moveToLogin = (e) => {
    e.preventDefault();
    const [email] = e.target;
    navigate(`/auth${search}`, { state: { emailAddress: email.value } });
  }

  return <Card shadow={false}>
    {!passwordReset && <AuthForm tagline="Enter Reset Code" handleSubmit={passwordReset ? moveToLogin : handleSubmit}>
      <InputField label="Email" placeholder={"Email"} type={"email"} required={true} defaultValue={emailAddress} disabled={true} />
      <InputField label="Reset Code" placeholder={"Reset Code"} type={"text"} required={true} />
      <PasswordField label="New Password" placeholder={"New Password"} required={true} />
      <PasswordField label="Confirm Password" placeholder={"Confirm Password"} required={true} />

      <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
      <FormSubmit>{passwordReset ? 'Login' : 'Reset Password'}</FormSubmit>
    </AuthForm>}

    {passwordReset && <CardBody>
      <SuccessMessage>
        Your password has been reset successfully, please proceed to login.
      </SuccessMessage>
    </CardBody>}

    <BottomLinks>
      <Link to={`/auth${search}`}>Back to Login</Link>
    </BottomLinks>
  </Card>
}

export default ResetPasswordPage;