import {
  Link,
  useNavigate 
} from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";

import {
  Typography,
  Button
} from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputRow from "../../components/form/inputRow";
import InputField from "../../components/form/inputField";
import TextAreaField from "../../components/form/textAreaField";
import FormSubmit from "../../components/form/formSubmit";
import FormImage from "../../components/form/formImage";

import personalInformationSVG from "../../assets/images/illustration-personal-information.svg";

const PersonalInformation = ({ 
  authenticated = false, defaultData = { firstName: '', lastName: '', orgName: '', orgDesc: '' },
  updateStore, toggleDialog, onSubmit 
}) => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [firstName, lastName, orgName, orgDesc] = e.target;
    const personalInfo = { 
      firstName: firstName.value, 
      lastName: lastName.value, 
      orgName: orgName.value, 
      orgDesc: orgDesc.value 
    };

    toggleDialog();
    updateStore(personalInfo);
    toggleDialog();

    onSubmit();
  }

  const doSignOut = () => {
    signOut();
    navigate(0);
  }

  return (
    <>
      <AuthForm 
        tagline={"Describe your Organization"} 
        handleSubmit={handleSubmit}
      >
        <InputRow>
          <InputField
            label={"First Name"}
            placeholder={"First Name"}
            defaultValue={defaultData.firstName}
            disabled={authenticated}
            required={true}
            type={"text"}
          />
          <InputField 
            label={"Last Name"}
            placeholder={"Last Name"} 
            defaultValue={defaultData.lastName}
            disabled={authenticated}
            required={true}
            type={"text"}
          />
        </InputRow>
        <InputField
          label="Organization Name"
          placeholder={"Organization Name"}
          defaultValue={defaultData.orgName}
          required={true}
          type={"text"}
        />
        <TextAreaField
          label="Organization Description"
          defaultValue={defaultData.orgDesc}
          required={true}
        />
        
        {authenticated &&
          <Typography
            variant="small"
          >
            Would you like to use a different account?
            <Button
              variant="text"
              color="orange"
              onClick={doSignOut}
            >
              Logout
            </Button>
          </Typography>
        }
        {!authenticated && 
          <Typography
            variant="small"
          >
            Already have an account?&nbsp;
            {/*TODO: add redirect back to this page to url */}
            <Link 
              to={"/auth"} 
              className="text-tempo-orange"
            >
              Login
            </Link>
            &nbsp;now!
          </Typography>
        }

        <FormSubmit>
          Next Step
        </FormSubmit>
      </AuthForm>
      <FormImage
        src={personalInformationSVG}
        alt={
          "Dark-skinned lady with a short bob cut wearing an orange shirt and black pants on a gray background " +
          "holding a string attached to a piece of paper with an orange avatar icon on top and lines of varying " + 
          "length alternating between gray and orange"
        }
      />
    </>
  );
}

export default PersonalInformation;