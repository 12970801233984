import { ThemeProvider, Input } from "@material-tailwind/react";

const InputField = ({
    label, error, success, icon, labelProps, containerProps, className, shrink, inputRef,
    defaultValue, disabled, minLength, onChange, placeholder, required, type
}) => {
  const inputTheme = {
    input: {
      styles: {
        base: {
          asterisk: {
            display: "hidden",
          },      
        },
      },
    }
  };

  return (
    <ThemeProvider value={inputTheme}>
      <Input
        variant="outlined"
        size="lg"
        color="orange"
        label={label}
        error={error}
        success={success}
        icon={icon}
        labelProps={labelProps}
        containerProps={containerProps}
        className={className}
        shrink={shrink}
        inputRef={inputRef}
        
        defaultValue={defaultValue}
        disabled={disabled}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        type={type}
        onChange={onChange}
      />
    </ThemeProvider>
  );
}

export default InputField;