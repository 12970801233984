import {
  useEffect,
  useState
} from "react";

import { Typography, Card, CardHeader, CardBody } from "@material-tailwind/react";
import PlanCard from "../../components/ui/planCard";
import FormSwitch from "../../components/form/formSwitch";
import AuthForm from "../../components/form/authForm";
import FormSubmit from "../../components/form/formSubmit";

import stripePaymentPNG from "../../assets/images/payment-stripe.png";

const SubscriptionSelection = ({ 
  selectedPackage, updateSelectedPackage, toggleDialog, onOrderCompleted
}) => {
  const [selectedPlan, setSelectedPlan] = useState(selectedPackage?.index || -1);
  const [displayPrice, setDisplayPrice] = useState(0);

  const subscriptionPlans = [
    {
      name: "Free",
      pricePerMonth: 0,
      pricePerYear: 0,
      description: "Test out what Moonbase offers!",
      perks: [
        "AI-guided content creation (limited)",
        "AI-guided quiz generation (limited)",
        "Group chat",
        "AI Tutor (limited)",
        "Max of 1 group",
        "1 Admin member",
        "2 Normal members"
      ],
    },
    {
      name: "Basic",
      pricePerMonth: 9.99,
      pricePerYear: 99.99,
      description: "Perfect for small to growing organizations",
      perks: [
        "AI-guided content creation (limited)",
        "AI-guided quiz generation (limited)",
        "Group chat",
        "AI Tutor (limited)",
        "Max of 3 group",
        "2 Admin member",
        "5 Normal members"
      ],
    },
    {
      name: "Silver",
      pricePerMonth: 14.99,
      pricePerYear: 149.99,
      description: "Perfect for medium-sized organizations",
      perks: [
        "AI-guided content creation (unlimited)",
        "AI-guided quiz generation (unlimited)",
        "Group chat",
        "AI Tutor (unlimited)",
        "Max of 10 group",
        "5 Admin members",
        "15 Normal members"
      ],
    },
    {
      name: "Platinum",
      pricePerMonth: 19.99,
      pricePerYear: 199.99,
      description: "Perfect for large organizations",
      perks: [
        "AI-guided content creation (unlimited)",
        "AI-guided quiz generation (unlimited)",
        "Group chat",
        "AI Tutor (unlimited)",
        "Unlimited number groups",
        "Unlimited admin members",
        "Unlimited normal members"
      ],
    }
  ];

  toggleDialog();

  useEffect(() => {
    updateSelectedPackage(selectedPlan, subscriptionPlans[selectedPlan]);
    // eslint-disable-next-line
  }, [selectedPlan, setSelectedPlan, updateSelectedPackage]);

  const submitForm = (e) => {
    e.preventDefault();
    /* TODO: Add functionality */

    //the line below should be called after order has been completed
    if (onOrderCompleted) {
      onOrderCompleted();
    }

  };

  return (
    <AuthForm
      tagline="Choose a Subscription Plan"
      handleSubmit={submitForm}
    >
      <FormSwitch
        className="lg:mb-12"
        values={[
          "Monthly",
          "Annually"
        ]}
        setState={setDisplayPrice}
      />
      <div
        className="flex justify-center gap-[1px] lg:gap-1"
      >
        {subscriptionPlans.map((e, index) => {
          return (
            <PlanCard
              e={{ ...e }}
              index={index}
              displayPrice={displayPrice}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              key={index}
            />
          );
        })}
      </div>
      <div
        className="lg:hidden mx-auto"
      >
        {selectedPlan < 0 ? "" :
          <Card
            color="gray"
            variant="gradient"
            className={[
              "w-[16rem] p-1 pt-2 lg:p-8",
            ].join(" ")}
          >
            <>
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="relative rounded-none border-b border-white/10 pb-6 text-left lg:text-center"
              >
                <Typography
                  variant="h4"
                  color="white"
                  className="flex justify-center gap-1 text-lg text-4xl"
                >
                  <span
                    className="text-xl"
                  >
                    $
                  </span>

                  {displayPrice === 0 ? subscriptionPlans[selectedPlan].pricePerMonth : subscriptionPlans[selectedPlan].pricePerYear}

                  <span
                    className="lg:self-end text-sm lg:text-xl"
                  >
                    {displayPrice === 0 ? "/mo" : "/yr"}
                  </span>
                </Typography>
                <Typography
                  variant="small"
                  className="mt-4 text-center text-tempo-gray font-normal"
                >
                  {subscriptionPlans[selectedPlan].description}
                </Typography>
              </CardHeader>
              <CardBody>
                <ul
                  className="flex flex-col gap-4"
                >
                  {subscriptionPlans[selectedPlan].perks.map((perk, id) => {
                    return (
                      <li
                        className="flex items-center gap-4"
                        key={id}
                      >
                        <span
                          className="rounded-full border border-tempo-black bg-tempo-green p-1"
                        >
                        </span>
                        <Typography
                          variant="small"
                          className="font-normal text-left"
                        >
                          {perk}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </CardBody>
            </>
          </Card>
        }
      </div>
      <img
        className="m-auto"
        src={stripePaymentPNG}
        width={400}
        alt={
          "A small rounded rectangle with black sides with the words Powered By followed by the Stripe logo on the upper left of the rectangle " +
          "with the logos for VISA, MasterCard, Maestro, American Express, and Discover Network in a row inside of the rectangle"
        }
      />
      <div className="block m-auto">
        <FormSubmit
          disabled={selectedPlan === -1 ? true : false}
        >
          Complete Order
        </FormSubmit>
      </div>
    </AuthForm>
  );
}

export default SubscriptionSelection;