import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";

import { deleteAccountRequest } from "../../services/authenticationService";

import { Card, Typography } from "@material-tailwind/react";
import AuthForm from "../../components/form/authForm";
import InputField from "../../components/form/inputField";
import ErrorMessage from "../../components/form/errorMessage";
import FormSubmit from "../../components/form/formSubmit";
import BottomLinks from "../../components/form/bottomLinks";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const DeleteAccountForm = ({ title }) => {
  const authenticatedUser = useAuthUser();
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const { search } = useLocation();

  // sets title of form in authentication layout
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle, authenticatedUser, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [email, password] = e.target;
    try {
      setDialogOpen(true);
      const forgotPasswordResult = await deleteAccountRequest(email.value, password.value);
      setDialogOpen(false);

      const { success, message } = forgotPasswordResult;
      if (success) {
        navigate(`/${search}`, { state: { emailAddress: email.value } });
      } else {
        setErrorMessage(message);
      }
    } catch (e) {
      setDialogOpen(false);
      setErrorMessage(e.toString());
    }
  }

  return <Card shadow={false}>
    <AuthForm tagline="Delete Account?" handleSubmit={handleSubmit}>
      <Typography
        variant="small"
        className={"flex items-center font-normal"}
      >
        You are about to delete your account on Moonbase<br /> and all data related to this account<br />
        Please note that this action is irreversible<br /> and will lead to permanent loss of data.
      </Typography>
      <InputField label="Email" placeholder={"Email"} type={"email"} defaultValue={authenticatedUser?.email} required={true} disabled={!!authenticatedUser} />
      <InputField label="Password" placeholder={"Password"} type={"password"} required={true} />

      <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
      <FormSubmit>Delete Account</FormSubmit>
    </AuthForm>

    <BottomLinks>
      <Link to={`/auth${search}`}>Back to Login</Link>
    </BottomLinks>
  </Card>
}

export default DeleteAccountForm;