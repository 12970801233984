import { Textarea } from "@material-tailwind/react";

const TextAreaField = ({ 
  label, labelProps, className, 
  defaultValue, required 
  }) => {
  return (
    <Textarea
      variant="outlined"
      size="lg"
      color="orange"
      label={label}
      labelProps={labelProps}
      className={className}

      defaultValue={defaultValue}
      required={required}    
    />
  );
}

export default TextAreaField;