import { useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";

import {
  Typography,
  Button
} from "@material-tailwind/react";

const ErrorPageNotFound = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();

  const doLogout = () => {
    signOut();
    navigate(0);
  }

  return (
    <main
      className="bg-tempo-black bg-accent min-h-screen w-screen flex flex-col items-center justify-center text-tempo-white text-center"
    >
      <Typography
        variant="h1"
        className="bg-tempo-black text-4xl font-motley"
      >
        This page is not available
      </Typography>
      <img
          className="rounded-[50%]"
          src="https://media.giphy.com/media/14uQ3cOFteDaU/giphy.gif"
          alt={[
            "An animated GIF of the Earth rotating from left to right with a cloud in the upper left with the number 4",
            "on its left and right side, with the occasional comet streaking past behind the Earth"
          ].join(" ")}
          height={300}
          width={500}
      />
      <Button
        className="mt-10"
        variant="gradient"
        color="orange"
        onClick={doLogout}
      >
        Logout
      </Button>
    </main>
  );
}

export default ErrorPageNotFound;