import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useSignIn from "react-auth-kit/hooks/useSignIn";

import useOnBoardingStore from "./states/onboarding_store";

import WizardHeader from "../../components/ui/wizardHeader";
import PersonalInformation from "./personalInformation";
import LoginInformation from "./loginInformation";
import EmailVerification from "./emailVerification";
import SubscriptionSelection from "./subscriptionSelect";

const MainOnboardingPage = ({
  title
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState(<PersonalInformation onSubmit={() => setActiveStep(1)} />);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const { setTitle } = useOutletContext();
  const { setDialogOpen } = useOutletContext();

  useEffect(() => {
    setTitle(title);
    // eslint-disable-next-line
  }, []);

  const authenticatedUser = useAuthUser();
  const authenticated = useIsAuthenticated();
  const isAuthenticated = !!authenticatedUser && authenticated;
  const signIn = useSignIn();

  const personalInfo = useOnBoardingStore((state) => state.personalInformation);
  const updatePersonalInfo = useOnBoardingStore((state) => state.updatePersonalInformation);
  const signInDetails = useOnBoardingStore((state) => state.signInDetails);
  const updateSignInDetails = useOnBoardingStore((state) => state.updateSigninDetails);
  const selectedPackage = useOnBoardingStore((state) => state.selectedPackage);
  const updateSelectedPackage = useOnBoardingStore((state) => state.updateSelectedPackage);
  const updateOrganizationInfo = useOnBoardingStore((state) => state.updateOrganizationInfo);

  const { search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const paramRedirectUrl = searchParams.get("redirectUrl");
  const redirectUrl = paramRedirectUrl ? (paramRedirectUrl + search) : null;

  const onOnboardingDone = useCallback(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate('/');
    }
  }, [redirectUrl, navigate]);

  useEffect(() => {
    if (isAuthenticated && (!personalInfo.firstName || !personalInfo.lastName)) {
      const { firstName, lastName, email } = authenticatedUser;

      updatePersonalInfo({ firstName, lastName });
      updateSignInDetails({ emailAddress: email });
    }
  }, [isAuthenticated, authenticatedUser, updatePersonalInfo, personalInfo, updateSignInDetails])

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setActiveForm(
          <PersonalInformation
            authenticated={isAuthenticated}
            defaultData={personalInfo}
            updateStore={updatePersonalInfo}
            toggleDialog={setDialogOpen}
            onSubmit={() => setActiveStep(1)}
          />
        );
        break;
      case 1:
        setActiveForm(
          <LoginInformation
            authenticated={isAuthenticated}
            personalInfo={personalInfo}
            defaultData={signInDetails}
            updateStore={updateSignInDetails}
            toggleDialog={setDialogOpen}
            onSubmit={() => setActiveStep(2)}
          />
        );
        break;
      case 2:
        setActiveForm(
          <EmailVerification
            authenticated={isAuthenticated}
            authenticatedUser={authenticatedUser}
            orgInfo={{ orgName: personalInfo.orgName, orgDesc: personalInfo.orgDesc }}
            signIn={signIn}
            signInDetails={signInDetails}
            updateOrganizationInfo={updateOrganizationInfo}
            updateEmailInState={(newEmail) => updateSignInDetails({ ...signInDetails, emailAddress: newEmail })}
            toggleDialog={setDialogOpen}
            onSubmit={() => setActiveStep(3)}
          />
        );
        break;
      case 3:
        setActiveForm(
          <SubscriptionSelection
            selectedPackage={selectedPackage}
            updateSelectedPackage={updateSelectedPackage}
            toggleDialog={setDialogOpen}
            onSubmit={() => setActiveStep(4)}
            onOrderCompleted={onOnboardingDone}
          />
        );
        break;
      default:
        setActiveForm(<h1>Page not implemented</h1>)
    }
    // eslint-disable-next-line
  }, [activeStep, personalInfo, updatePersonalInfo, signInDetails, updateSignInDetails, selectedPackage, updateSelectedPackage]);

  return (
    <>
      <WizardHeader
        activeStep={activeStep}
        setActiveStep={(s) => {
          if (activeStep === 1 && s === 0) return setActiveStep(s)
        }}
        isFirstStep={isFirstStep}
        setIsFirstStep={setIsFirstStep}
        isLastStep={isLastStep}
        setIsLastStep={setIsLastStep}
      />

      <div
        className="flex flex-row w-full"
      >
        {activeForm}
      </div>
    </>
  );
};

export default MainOnboardingPage;