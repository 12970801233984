/* Post request, body: email, password */
const loginEndpoint = "/auth/user/login";

/* Post request, body: firstName, lastName, phoneNumber, password, confirmPassword, userType, email, code */
const signupEndpoint = "/auth/user/create";

/* Get request, param:code e.g /auth/user/confirm-email/jfieow34r4 */
const verifyEmailEndpoint = "/auth/user/confirm-email";

/* Post request, body: oldEmail, newEmail, password */
const changeEmailEndpoint = "/auth/user/email-change";

/* Post request, body: email*/
const resendVerificationEmailEndpoint = "/auth/user/resend-verify-email";

/* Post request, body: email*/
const forgotPasswordEndpoint = "/auth/user/forgot-password";

/* Post request, body: email, code, password, confirmPassword*/
const resetPasswordEndpoint = "/auth/user/verify-forgot-password";

/* Post request, body: image(a file - optional), organizationName, description */
const createOrganizationEndpoint = "/organization/auth/createorg";

/* Post request, body: email, password */
const deleteAccountEndpoint = "/auth/user/self-delete";

export {
  loginEndpoint,
  signupEndpoint,
  verifyEmailEndpoint,
  changeEmailEndpoint,
  resendVerificationEmailEndpoint,
  createOrganizationEndpoint,
  forgotPasswordEndpoint,
  resetPasswordEndpoint,
  deleteAccountEndpoint,
};