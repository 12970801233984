import { Link } from "react-router-dom";

const NavMenuLink = ({
    to, label,
    className
}) => {
  return (
    <li>
      <Link
        to={to}
        className={[
          className,
          "text-tempo-black opacity-70 hover:opacity-100 duration-300"
        ].join(" ")}
      >
        {label}
      </Link>
    </li>
  );
}

export default NavMenuLink;