import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import NavMenu from "../ui/navMenu";
import NavButton from "../ui/navButton";

import moonbaseIcon from "../../assets/images/moonbase_icon.png";

const LandingLayout = () => {
  const [title, setTitle] = useState("");
  const currentYear = new Date().getFullYear();

  // TODO: scroll to top on component mount

  return ( 
    <div
      className="bg-tempo-black bg-accent min-h-screen w-screen"
    >
      <header
        className="flex sticky top-0 z-50 shadow-lg items-center justify-between bg-tempo-gray rounded-b-[20px] px-[8.33%] py-1 h-16 w-full"
      >
        <img 
          className="rounded-lg object-cover z-[9999]" 
          src={moonbaseIcon} 
          alt="Moonbase Icon with a yellow-to-orange gradient background that has an orange moon in front with craters and a white atmosphere 
            that has the letter M in front with hyperrealistic tech artifacts adorning the surface" 
          height={50} 
          width={50} 
        />
        <NavMenu />
        <NavButton
          to={"/book-demo"}
          label={"Book a Demo"}
          className={"hidden md:inline-flex"}
        />
      </header>
      <main
        className="min-h-screen w-screen text-tempo-white"
      >
        <Outlet 
          context={{
            title, setTitle
          }} 
        />
      </main>
      <div
        className="shadow-[0_0_25px] shadow-tempo-white bg-tempo-blue rounded-t-[50px] h-10"
      />
      <footer
        className="bg-tempo-blue p-4 text-tempo-white text-center text-base font-roboto font-semibold"
      >
        <div className="site-map">

        </div>
        <div
          className="flex flex-col-reverse md:flex-row gap-y-2 md:justify-around"
        >
          <address>
            &copy;{currentYear}&nbsp; 
            Tempo Space, Inc. All rights reserved.
          </address>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/contact-us">Contact Support</Link>
        </div>
      </footer>
    </div>
  );
};

export default LandingLayout;